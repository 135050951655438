import React from 'react';
import { useCompany, useScreens } from '../Store/storeFunctions';
import { AgGridReact } from 'ag-grid-react';
import { YesNoCellRenderer } from '../Helpers/AgGridCellRenderers';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import FormBuilder from '../Form/FormBuilder';
import * as FormBuilderData from '../Form/BuilderTools/FormBuilderData';

function FormBuilderList(props) {
  const [edit, setEdit] = React.useState();
  const [forms, setForms] = React.useState([]);
  const [create, setCreate] = React.useState();
  const [activeFilter, setActiveFilter] = React.useState(true);

  const userCompany = useCompany();
  const screens = useScreens();
  const gridRef = React.useRef();

  const DEFINITION = 'DEFINITION';

  const isSystem = props.isSystem || userCompany.companyData?.code === "tcpSystemAdministration";

  React.useEffect(() => {
    fetchForms();
  }, [activeFilter]);

  async function fetchForms() {
    var data = await FormBuilderData.getForms(userCompany.companyData?.code, activeFilter);

    if (data)
        setForms(data);
  }

  function refresh() {
    fetchForms();
  }

  function clearForm() {
    screens.set("Rules");
    setEdit("");
  }

  function formID() {
    if (screens.state.currentSubScreens.length > 0) {
      var index = screens.state.currentSubScreens.findIndex(o => o.type === "FormRules");

      if (index > -1)
        return screens.state.currentSubScreens[index].data;
      else
        return undefined;
    } else {
      return undefined;
    }
  }

  function editFormDefinition() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length === 1) {
      const data = rows[0];
      
      screens.setSubScreen({
        content: data.Name,
        data: data.ID,
        type: "FormRules"
      });
      setEdit(DEFINITION);
    }
  }

  const systemFormsColumnDefs = [
    {
      checkboxSelection: true,
      valueGetter: (params) => params.data.ID,
      resizable: false,
      width: 50,
      sortable: true,
      filter: true
    },
    {
      field: 'Name',
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'StaticLink',
      resizable: true,
      sortable: true,
      filter: true,
      valueGetter: (params) => {
        if (params.data.Active && params.data.Type) {
          return `https://${process.env.REACT_APP_DOMAIN_NAME}/form/${userCompany.companyData?.code}/${params.data.Type}`;
        }
      }
    },
    {
      field: 'Type',
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'Active',
      valueGetter: (params) => {
        if (params.data.Active) {
          return "YES";
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'Published',
      valueGetter: (params) => {
        if (params.data.Published) {
          return "YES";
        } else {
          return "NO";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true
    },
    {
      field: 'DisableInheritance',
      valueGetter: (params) => {
        if (params.data.DisableInheritance) {
          return "NO";
        } else {
          return "YES";
        }
      },
      cellRenderer: YesNoCellRenderer,
      resizable: true,
      sortable: true,
      filter: true,
      headerName: "Uses System Questions"
    }
  ]

  function renderFormTable() {
    return <div style={{ height: 200, width: '90%', position: 'relative' }} className="ag-theme-balham">
      <button className="btn btn-info" style={{position: 'absolute', right: 0, top: -45}} onClick={()=>refresh()}><span className="bi-arrow-clockwise"></span></button>
        <AgGridReact
          ref={gridRef}
          rowData={forms}
          columnDefs={systemFormsColumnDefs}
          suppressRowClickSelection={true}
          enableCellTextSelection={true}
          rowSelection="single">
        </AgGridReact>
    </div>
  }

  if (formID()) {
    switch (edit) {
      case DEFINITION: return (
            <FormBuilder
                FormID={formID()}
                company={userCompany.companyData?.code}
                editOptions={props.editOptions}
                editAdmins={props.editAdmins}
                viewSubmissions={props.viewSubmissions}
                back={clearForm}
            />
        );
    }
  } else if (create) {
    return (
        <FormBuilder
            company={userCompany.companyData?.code}
            editOptions={props.editOptions}
            editAdmins={props.editAdmins}
            viewSubmissions={props.viewSubmissions}
            back={clearForm}
            createNew={create}
            />
    );
  }

  return <div>
    <div className="container-fluid" style={{ margin: 8 }}>
      <div className="row" style={{ marginRight: 50, marginLeft: 50 }}>

        <h5 className="col-sm-7">{isSystem ? `Edit Forms For - ${userCompany.companyData?.code}` : ``}</h5>

        <div className='col-lg-2'>
            <button type="button" className="btn btn-sm btn-primary" onClick={()=> {
              setActiveFilter(!activeFilter);
            }}>Show { activeFilter ? "Disabled" : "Enabled" } Forms</button>
        </div>

        <div className='col-lg-1'>
            <button type="button" className="btn btn-sm btn-primary" onClick={()=>editFormDefinition()}>Edit Form</button>
        </div>

        <div className='col-lg-1'>
            <button type="button" className="btn btn-sm btn-primary" onClick={()=>setCreate(true)}>Create Form</button>
        </div>
      </div>
      
      <div className="row" style={{ marginTop: 10 }}>
        <div className="col-3">
          <img src="../assets/forms icon.png" style={styles.formsicon} width={250}></img>
        </div>

        <div className="col-9">
          {renderFormTable()}
        </div>
      </div>
    </div>
    
  </div>;
}

const styles = {
  formsicon: {
    width: '80%'
  }
}

export default FormBuilderList;
import React from 'react';

import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';
import { saveCompanyContacts } from '../../Helpers/CompanyData';
import Modal from "react-modal";
import { useCompany, useContacts } from '../../Store/storeFunctions';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';


/*
  Props shape:
    contactID - number
    close - function
    createNew - bool
    phoneNumber - string - to prepop when new is selected
*/

function ContactImport(props) {
  const [newContacts, setNewContacts] = React.useState();

  const companyStore = useCompany();
  const contactStore = useContacts();

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = React.useState(false);
  const [removeHoverColor, setRemoveHoverColor] = React.useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );

  function saveNewContacts() {
    toastPromise(async () => {
      let dataToSave = [];

      var passes = true;

      if (passes) {
        dataToSave = [ ...contactStore.contacts, newContacts];

        await saveCompanyContacts(companyStore.companyData.code, dataToSave);

        props.close();
      } else {
        throw new Error("Unable to parse CSV file")
      }
    }, false);
  }

  function convertRowsToContacts(data) {
    var headers = data[0];
    var contacts = [];

    for (let i = 1; i < data.length; i++) {
      var contact = {};

      for (let j = 0; j < headers.length; j++) {
        var field = headers[j];

        switch (field) {
          case "FirstName":
          case "LastName":
          case "PhoneNumber":
          case "Email":
          case "Notes":
            contact[field] = data[i][j] || "";
            break
          default:
            console.log("Unknown field", field);
            break;
        }
      }

      contacts.push(contact);
    }

    return contacts;
  }

  function render() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={() => props.close()}
        contentLabel={`Contact Details`}
        portalClassName="ContactModal"
      >
        <div className="ContactModalHeader">Upload Contacts (CSV)</div>

        <div className="ContactModalContent" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <div className="container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className='row'>              
              <CSVReader
                onUploadAccepted={(results) => {
                  console.log('---------------------------');
                  console.log(results);
                  console.log('---------------------------');
                  var newContacts = convertRowsToContacts(results.data);

                  console.log('---------------------------');
                  console.log(newContacts);
                  console.log('---------------------------');

                  setNewContacts(newContacts);
                  setZoneHover(false);
                }}
                onDragOver={(event) => {
                  event.preventDefault();
                  setZoneHover(true);
                }}
                onDragLeave={(event) => {
                  event.preventDefault();
                  setZoneHover(false);
                }}
              >
                {({
                  getRootProps,
                  acceptedFile,
                  ProgressBar,
                  getRemoveFileProps,
                  Remove,
                }) => (
                  <>
                    <div
                      {...getRootProps()}
                      style={Object.assign(
                        {},
                        styles.zone,
                        zoneHover && styles.zoneHover
                      )}
                    >
                      {acceptedFile ? (
                        <>
                          <div style={styles.file}>
                            <div style={styles.info}>
                              <span style={styles.size}>
                                {formatFileSize(acceptedFile.size)}
                              </span>
                              <span style={styles.name}>{acceptedFile.name}</span>
                            </div>
                            <div style={styles.progressBar}>
                              <ProgressBar />
                            </div>
                            <div
                              {...getRemoveFileProps()}
                              style={styles.remove}
                              onMouseOver={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                              }}
                              onMouseOut={(event) => {
                                event.preventDefault();
                                setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                              }}
                            >
                              <Remove color={removeHoverColor} />
                            </div>
                          </div>
                        </>
                      ) : (
                        'Drop CSV file here or click to upload'
                      )}
                    </div>
                  </>
                )}
              </CSVReader>
            </div>

            {
              !newContacts ? null :
              <div className='row' style={{ marginTop: 20 }}>
                <h4>Preview</h4> <hr />
                {
                  newContacts.map(c => <div>
                    <p>
                      <b>First/Last Name -</b> {c.FirstName} {c.LastName};&nbsp;
                      <b>Phone -</b> {c.PhoneNumber};&nbsp;
                      <b>Email -</b> {c.Email};&nbsp;
                      <b>Notes -</b> {c.Notes};&nbsp;
                    </p>
                  </div>)
                }

                <div className="mb-3 center"> 
                  <button className="btn btn-sm btn-primary" type='button' onClick={saveNewContacts}>Save Imported Contacts</button>
                </div>
              </div>
            }
          </div>
        </div>
      </Modal>
    );
  }

  return render();
}

const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

export default ContactImport;
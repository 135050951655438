import './App.scss';
import { useParams } from 'react-router-dom';
import { useCompany, useScreens, useUser } from './Store/storeFunctions';

function Nav() {
    const { id } = useParams();

    const screens = useScreens();
    const user = useUser();
    const company = useCompany();

    function renderSubScreens() {
        if (screens.state.currentSubScreen && screens.state.currentSubScreens?.length) {
            var btns = screens.state.currentSubScreens.map((v, i) => {
                if (v) {
                    var classString = "list-group-item list-group-item-action";

                    if (screens.state.currentSubScreen === v) {
                        classString += " list-group-item-primary";
                    }

                    return <div key={`subscreen_${i}`} className={classString} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        screens.setSubScreen({ content: v.content, type: v.type });
                    }}>{v.content}</div>;
                }

                return null;
            });

            return <div className="list-group">{btns}</div>;
        } else {
            return null;
        }
    }

    function renderBreadCrumbs() {
        var screenObj = screens.state.navOptions.adminOptions.find(s => s.screen === screens.state.currentScreen);

        var elems = [(
            <div key={`navbreadcrumbs_-1`} style={styles.currentScreen}>
                {screenObj.content}
            </div>
        )];

        if (screens.state.currentSubScreens?.length) {
            elems.push(...screens.state.currentSubScreens.map((v, i) => {
                return <div key={`navbreadcrumbs${i}`} style={styles.subScreen}>
                    <div className="vr"></div>
                    {v.content}
                </div>
            }));
        }

        if (screenObj?.requiresUser && !user.user) {
            return [(
                <div key={`navlogin`} style={styles.currentScreen}>
                    Login
                </div>
            )];
        }

        return elems;
    }

    function renderButtons() {
        var groupedButtons = [];
        var nonGroupedButtons = [];

        if (screens.state.navOptions.adminOptions) {
            for (let i = 0; i < screens.state.navOptions.adminOptions.length; i++) {
                if (screens.state.navOptions.adminOptions[i].requiresUser && !user.user)
                    continue;

                if (!screens.state.navOptions.adminOptions[i].requiresUser && user.user)
                    continue;

                if (screens.state.navOptions.adminOptions[i].systemAdminOnly && id !== 'tcpSystemAdministration') {
                    continue;
                }

                if (screens.state.navOptions.adminOptions[i].requiresMessaging && !company.companyData?.extraFeatures?.messaging)
                  continue;

                if (screens.state.navOptions.adminOptions[i].grouped) {
                    groupedButtons.push(screens.state.navOptions.adminOptions[i]);
                } else {
                    nonGroupedButtons.push(screens.state.navOptions.adminOptions[i]);
                }
            }
        }

        var grouped = (
            <li className="list-group-item btn-group">
                <a className="dropdown-toggle" data-toggle="dropdown" style={{ height: '100%', width: '100%' }}>
                    <i className="bi-list" />
                </a>
                <ul className="dropdown-menu" role="menu">
                    {
                        groupedButtons.map((v, i) => {
                            if (v.content) {
                                if (v.click) {
                                    return <li key={`navlibtn${i}`}><a onClick={() => {
                                        v.click();
                                    }}>{v.content}</a></li>;
                                } else if (v.link) {
                                    return <li key={`navlibtn${i}`}><a href={v.link}>{v.content}</a></li>;
                                } else if (v.screen) {
                                    return <li key={`navlibtn${i}`}><a onClick={() => {
                                        screens.set(v.screen);
                                    }}>{v.content}</a></li>;
                                }
                            }

                            return null;
                        })
                    }
                </ul>
            </li>
        );

        var ungrouped = nonGroupedButtons.map((v, i) => {
            if (v.content) {
                var classString = "list-group-item list-group-item-action";
                var btn = null;
                var subScreens = null;

                if (screens.state.currentScreen == v.screen) {
                    subScreens = renderSubScreens();

                    if (!subScreens)
                        classString += " list-group-item-primary";
                }

                if (v.click) {
                    btn = <div key={`navbtn${i}`} className={classString} onClick={() => {
                        v.click();
                    }}>{v.content}{subScreens}</div>;
                } else if (v.link) {
                    btn = <div key={`navbtn${i}`} className={classString} href={v.link}>{v.content}{subScreens}</div>;
                } else if (v.screen) {
                    btn = <div key={`navbtn${i}`} className={classString} onClick={() => {
                        screens.set(v.screen);
                    }}>{v.content}{subScreens}</div>;
                }

                return btn;
            }

            return null;
        });

        if (groupedButtons.length > 0 && nonGroupedButtons.length > 0) {
            return <div className="list-group">{ungrouped}{grouped}</div>;
        } else if (groupedButtons.length > 0) {
            return <div className="list-group">{grouped}</div>;
        } else {
            return <div className="list-group">{ungrouped}</div>;
        }
    }

    return (screens.state.navOptions.adminOptions.find(s => s.screen == screens.state.currentScreen)).requiresUser && !user.user ? null : (
        <div>
            <div className="d-flex flex-row justify-content-between" style={{ padding: '20px 140px', zIndex: 30, background: 'white' }} >
                <div>
                    <img src="../assets/logo-1.png" width={354} height={54}></img>
                    {process.env.REACT_APP_ENV == 'Dev' ? <span style={styles.devStyle}> - DEV</span> : null}
                    {/* <caption>
                        <a className="navbar-brand" style={{ height: "auto" }} href="./">
                            {company.companyData?.code ? <div style={{
                                fontSize: 12,
                                fontWeight: 'lighter',
                                fontStyle: 'italic'
                            }}><i>{company.companyData?.code}</i></div> : null}
                        </a>
                    </caption> */}
                </div>
                <button className="btn btn-sm btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#menu" style={styles.menuNavBtn}>
                    <span>MENU</span><i className="bi-list" />
                </button>
            </div>
            <div style={styles.breadCrumbContainer}>
                {renderBreadCrumbs()}
            </div>

            <div className="offcanvas offcanvas-end" id="menu" style={styles.offCanvasStyle}>
                <div className="offcanvas-body">
                    {renderButtons()}
                </div>
            </div>
        </div>
    );
}

const styles = {
    menuNavBtn: {
        width: '122px',
        height: '50px',
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center'
    },
    breadCrumbContainer: {
        height: '70px',
        backgroundColor: 'var(--bs-light-blue)',
        display: 'flex',
        alignItems: 'center',
        padding: '0 140px'
    },
    currentScreen: {
        color: 'var(--bs-primary)',
        fontSize: '36px',
        lineSpacing: '49px',
        fontFamily: 'Open Sans',
        fontWeight: 'bolder',
        paddingRight: '10px'
    },
    subScreen: {
        color: 'var(--bs-primary)',
        fontSize: '26px',
        lineSpacing: '36px',
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        display: 'flex',
        gap: '10px'
    },
    offCanvasStyle: {
        height: 'max-content',
        top: 80,
        right: 20,
        width: 375,
        borderRadius: 15
    },
    devStyle: {
        fontFamily: 'Open Sans, Regular',
        fontSize: '35px',
        color: '#045AA1',
        verticalAlign: 'middle'
    }
};
export default Nav;

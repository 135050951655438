import React from 'react';
import { toastPromise } from '../../Helpers/ToastMessage';
import { getFunctions, httpsCallable } from "firebase/functions";
import AutoComplete from "react-autocomplete";

import { useCompany, useContacts, useMessages } from '../../Store/storeFunctions';
import * as ContactTools from "./Contacts";

import Setup from './Setup';
import ContactDetails from './ContactDetails';

const functions = getFunctions();
const sendSMS = httpsCallable(functions, "sendSMS");
const markMessageUnread = httpsCallable(functions, "markMessageUnread");
const markMessagesRead = httpsCallable(functions, "markMessagesRead");

function MessagingService(props) {
  const company = useCompany();
  const contacts = useContacts();
  const messageStore = useMessages();

  const [messages, setMessages] = React.useState([]);
  const [newConversation, setNewConversation] = React.useState();
  const [newConversationNumber, setNewConversationNumber] = React.useState('');
  const [readyForConversation, setReadyForConversation] = React.useState(false);
  const [newMessage, setNewMessage] = React.useState("");

  const [focusedContactId, setFocusedContactId] = React.useState();
  const [viewDetailedContact, setViewDetailedContact] = React.useState();
  const [createNewContact, setCreateNewContact] = React.useState();
  const [searchFilter, setSearchFilter] = React.useState();

  React.useEffect(() => {
    if (company.companyData.twilio?.sid && company.companyData.twilio?.phoneNumber && company.companyData.twilio?.verified) {
      setReadyForConversation(true);
    }
  }, [company.companyData.twilio?.sid, company.companyData.twilio?.phoneNumber, company.companyData.twilio?.verified]);

  React.useEffect(() => {
    if (messageStore.messages?.Messages && company.companyData.twilio?.phoneNumber && company.companyData.twilio?.verified) {
      const messageContainer = messageStore.messages; // Messages from Messages.ts

      // flatten 2d array, map objects to their contact id, then reduce to a set
      const jsonResult = messageContainer.Messages.flat()
        .map((m) => {
          return {
            ...m,
            ContactID: m.From == company.companyData.twilio?.phoneNumber ? m.To : m.From
          };
        })
        .reduce((grouped, item) => {
          var index = grouped.findIndex(c => c.ContactID == item.ContactID);

          if (index == -1) {
            grouped.push({
              ContactID: item.ContactID,
              DateTime: item.DateTime,
              Messages: []
            });

            index = grouped.length - 1;
          }

          grouped[index].Messages.push(item);
          grouped[index].DateTime = new Date(grouped[index].DateTime) > new Date(item.DateTime) ? grouped[index].DateTime : item.DateTime;

          return grouped;
        }, [])
        .toSorted((l, r) => new Date(r.DateTime) - new Date(l.DateTime));

      setMessages(jsonResult);
    }
  }, [messageStore.messages, company.companyData.twilio?.phoneNumber, company.companyData.twilio?.verified]);

  React.useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("focusedConversation");

      if (!element) {
        return;
      }
      
      element.scrollTo({
        top: element.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }, [messages]);

  function createContact() {
    setCreateNewContact(true);
  }

  function viewContact(id) {
    setViewDetailedContact(id);
  }

  function closeViewContact() {
    setViewDetailedContact(undefined);
    setCreateNewContact(false);
  }

  // Function to switch to a new conversation view
  function viewNewConversation() {
      // Set the newConversation flag to true to display the new conversation interface
      setNewConversation(true);
      
      // Clear the currently focused contact ID since we're starting a new conversation
      // This ensures we're not showing any previous contact's information
      setFocusedContactId("");
  }
  

  function getContactMessages(contactId) {
    return messages.find(o => o.ContactID.replace(/\D/g,'').endsWith(contactId.replace(/\D/g,'')) || contactId.replace(/\D/g,'').endsWith(o.ContactID.replace(/\D/g,'')))?.Messages;
  }

  function renderMessagesByContact() {
    if ((!focusedContactId && !newConversation) || !messages) {
      return <></>
    }

    let contact = null;
    let content = <></>;
    let header = <></>;

    if (focusedContactId && getContactMessages(focusedContactId)) {
      contact = ContactTools.getContactByPhone(contacts.contacts, focusedContactId);
      let isRead = getContactMessages(focusedContactId)?.filter(v => !v.Read && v.ContactID == v.From)?.length == 0;

      content = getContactMessages(focusedContactId).map(m => {
        return <div style={{
            margin: 5
          }}>
          <p style={{
            backgroundColor: m.From == company.companyData.twilio?.phoneNumber ? "white" : "var(--bs-light-blue)",
            maxWidth: 300,
            float: m.From == company.companyData.twilio?.phoneNumber ? 'right' : 'left',
            padding: 8,
            borderRadius: 8,
            wordWrap: 'break-word'
          }}>{m.Body} <br /> <small style={{ color: 'rgba(0,0,0,0.4)', fontSize: 12 }}><i>{new Date(m.DateTime).toLocaleString()}</i></small></p>
        </div>
      });

      header = <div>
        <h4 style={{ margin: 12, display: 'inline-block' }}>{contact ? `${contact.FirstName} ${contact.LastName}` : focusedContactId || ""}</h4>

        {
          contact
          ? <span style={{ cursor: 'pointer' }} title='View Contact Details' onClick={() => viewContact(contact.ID)}><i className="bi bi-zoom-in"></i></span>
          : <button className="btn btn-sm btn-primary" type="button" onClick={createContact}>Create Contact</button>
        }

        {
          !isRead ? null
          : <span style={{ cursor: 'pointer', marginLeft: 8 }} title='Mark as unread' onClick={() => {
            toastPromise(async () => {
              const result = await markMessageUnread({
                phoneNumber: focusedContactId,
                company: company.companyData?.code
              });
            }, false, "Marking unread...", "Done!", "Failed to mark as unread.");
            
          }}><i className="bi bi-app-indicator"></i></span>
        }
      </div>;
    } else if (newConversation) {
      let headerText = "New Conversation";

      if (newConversationNumber.length >= "10") {
        contact = ContactTools.getContactByPhone(contacts.contacts, newConversationNumber);
        headerText = (contact ? `${contact.FirstName} ${contact.LastName}` : newConversationNumber) + " - " + headerText;
      }
      
      header = <div>
        <h4 style={{ margin: 12, display: 'inline-block' }}>{headerText}</h4>
      </div>;
    }

    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {header}

      <div id='focusedConversation' style={{
        height: "calc(100% - 104px)",
        overflowY: 'scroll',
        padding: 12,
        display: 'flex',
        flexDirection: 'column'
      }}>
        {content}
      </div>
      
      {
        !newConversation ? null :
        <div className="input-group p-2">
          <AutoComplete
            inputProps={{
              className: "form-control form-control-sm",
              placeholder: "To"
            }}
            wrapperStyle={{
              width: '100%'
            }}
            menuStyle={{
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              background: 'rgba(255, 255, 255, 0.9)',
              padding: '2px 0',
              fontSize: '90%',
              position: 'fixed',
              overflow: 'auto',
              maxHeight: '50%',
              zIndex: 99
            }}
            getItemValue={(item) => item.PhoneNumber}
            items={contacts.contacts}
            sortItems={(l, r) => `${r.FirstName} ${r.LastName}` - `${l.FirstName} ${l.LastName}`}
            shouldItemRender={(item, value) => `${item.FirstName} ${item.LastName} - ${item.PhoneNumber} - ${item.Email}`.toLowerCase().indexOf(value.toLowerCase()) > -1}
            renderItem={(item, isHighlighted) => {
                let style = { background: isHighlighted ? 'lightgray' : 'white', paddingLeft: 2 };
                let label = `${item.FirstName} ${item.LastName} - ${item.PhoneNumber} - ${item.Email}`;

                if (newConversationNumber) {
                  let index = label.toLowerCase().indexOf(newConversationNumber?.toLowerCase());
                  let end = index + newConversationNumber.length;

                  return <div style={style}>
                    {label.substring(0, index)}<b>{label.substring(index, end)}</b>{label.substring(end)}
                  </div>
                }

                return <div style={style}>
                  {label}
                </div>
              }
            }
            renderInput={(props) => {
              return <input {...props} />
            }}
            value={newConversationNumber}
            onChange={(e) => setNewConversationNumber(e.target.value)}
            onSelect={(val) => setNewConversationNumber(val)}
          />
        </div>
      }

      <div className="input-group p-2" style={{ zIndex: 0 }}>
        <textarea className="form-control form-control-sm"  placeholder='Text Message - SMS' value={newMessage} onChange={(e) => setNewMessage(e.target.value)} style={{ width: "calc(100% - 60px)" }} aria-describedby='btnSend' />
        <button className="form-control btn btn-sm btn-primary" id='btnSend' type="button" onClick={sendReply}><span className="bi-send"></span></button>
      </div>
    </div>
  }

  function renderExistingConversations() {
    return <div style={{ height: '100%' }}>
      <div className='container' style={{ height: '90%', padding: 10 }}>
        <div className='row' style={{ height: '100%', position: 'relative' }}>
          <div className='col-md-3' style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'whitesmoke',
            overflowY: 'scroll',
            height: '100%',
            padding: 0,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            borderRightWidth: 1,
            borderRightStyle: 'solid'
          }}>
            <div className='p-2'>
              <span className='input-group' style={{ width: 'calc(100% - 45px)', display: 'inline-flex', marginRight: 4 }}>
                <input className="form-control form-control-sm" type='text' placeholder='Quick Search' value={searchFilter} style={{ width: 'calc(100% - 55px)', height: '100%' }} onChange={e => setSearchFilter(e.target.value)}></input>
                <button className="form-control btn btn-sm btn-primary" type="button" title='Search' onClick={() => { console.log("Doing nothing") }}><span className="bi-search"></span></button>
              </span>

              <button className="btn btn-sm btn-primary" type="button" title='New Conversation' onClick={() => { viewNewConversation() }}><span className="bi-plus-square-fill" style={{ fontSize: 20, display: 'inline-block' }}></span></button>
            </div>

            {
              messages.map(c => {
                let contact = ContactTools.getContactByPhone(contacts.contacts, c.ContactID);

                if (searchFilter) {
                  let lowerSearch = searchFilter.toLowerCase();
                  let matches = true;
                  
                  if (contact) {
                    matches = contact.PhoneNumber.toLowerCase().includes(lowerSearch)
                      || contact.FirstName.toLowerCase().includes(lowerSearch)
                      || contact.LastName.toLowerCase().includes(lowerSearch)
                      || contact.Email.toLowerCase().includes(lowerSearch);
                  } else {
                    matches = c.ContactID.toLowerCase().includes(lowerSearch);
                  }

                  matches = matches || c.Messages.find(text => text.Body.toLowerCase().includes(lowerSearch));
                  
                  if (!matches) {
                    return null;
                  }
                }

                let newCount = c.Messages.filter(v => !v.Read && v.ContactID == v.From).length;

                return <><div onClick={() => {
                  setFocusedContactId(c.ContactID);
                  setNewConversation(false);
                  setNewConversationNumber("");

                  if (newCount > 0 && company.companyData?.code) {
                    markMessagesRead({
                      phoneNumber: c.ContactID,
                      company: company.companyData.code
                    });
                  }

                  setTimeout(() => {
                    const element = document.getElementById("focusedConversation");
                    element.scrollTo({
                      top: element.scrollHeight,
                      left: 0,
                      behavior: "smooth",
                    });
                  }, 100);
                }} style={{ padding: 12, paddingBottom: 0, borderBottom: '1px solid black', cursor: 'pointer' }}>
                  <p>
                    {contact ? `${contact.FirstName} ${contact.LastName}` : c.ContactID}
                    {!newCount ? null : <span className="badge rounded-pill bg-danger" style={{ float: 'right' }}>{newCount}</span>}
                    <br/><small><i>{new Date(c.Messages.at(-1).DateTime).toLocaleString()}</i></small>
                  </p>

                  <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: 'hidden' }} >{c.Messages.at(-1).Body}</p>
                </div>
                </>
              })
            }
          </div>
          
          <div className='col-md-9' style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'whitesmoke',
            height: '100%',
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            padding: 0,
            paddingTop: 8
          }}>
            {renderMessagesByContact()}
          </div>
        </div>
      </div>
    </div>
  }

  function sendReply() {
    let messageText = newMessage;
    setNewMessage("");

    toastPromise(async () => {
      const result = await sendSMS({
        from: company.companyData.twilio.phoneNumber,
        to: newConversation ? newConversationNumber : focusedContactId,
        message: messageText,
        company: company.companyData.code
      });

      if (newConversation) {
        setNewConversation(false);
        setNewConversationNumber("");
        setFocusedContactId(newConversationNumber);
      }
    }, false, "Sending Text Message...", "Sent!", "Failed to send.")
  }

  function render() {
    if (!readyForConversation) {
      return <Setup />
    }

    return (
      <div className="container" style={{ marginTop: 10, height: '100%' }}>
        <div className='row' style={{ padding: 20, paddingBottom: 0, height: 'calc(100% - 50px)', minHeight: 300 }}>
          <div style={{ height: '100%' }}>{renderExistingConversations()}</div>
        </div>

        { viewDetailedContact == undefined ? null : <ContactDetails contactID={viewDetailedContact} close={closeViewContact} /> }
        { !createNewContact ? null : <ContactDetails createNew={createNewContact} phoneNumber={focusedContactId} close={closeViewContact} /> }
      </div>
    );
  }

  return render();
}

const styles = {
}

export default MessagingService;
import React from 'react';
import Modal from "react-modal";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCompany, useContacts } from '../../Store/storeFunctions';
import { toastPromise } from '../../Helpers/ToastMessage';
import Bubble from '../../Helpers/Bubble';
/*
  Props shape:
    contacts - string[] - ids for contacts
    close - function
*/

const functions = getFunctions();
const sendBulkSMS = httpsCallable(functions, "sendBulkSMS");

function SendQuickText(props) {
  const [newMessage, setNewMessage] = React.useState("");

  const companyStore = useCompany();
  const contactStore = useContacts();


  React.useEffect(() => {
    if (!companyStore.companyData.twilio?.sid ||
      !companyStore.companyData.twilio?.phoneNumber ||
      !companyStore.companyData.twilio?.verified ||
      !contactStore.contacts ||
      !props.contacts
    ) {
      props.close();
    }
  }, []);

  function sendMessage() {
    let messageText = newMessage;
    setNewMessage("");

    toastPromise(async () => {
      const contacts = contactStore.contacts.filter(c => props.contacts.includes(c.ID) && c.PhoneNumber);

      const result = await sendBulkSMS({
        from: companyStore.companyData.twilio.phoneNumber,
        to: contacts.map(c => c.PhoneNumber),
        message: messageText,
        company: companyStore.companyData.code
      });
      
      props.close();
    }, false, "Sending Text Messages...", "Sent!", "Failed to send.");
  }

  function renderContacts() {
    var list = props.contacts.map(c => {
      const contact = contactStore.contacts.find(sc => sc.ID == c);

      if (!contact) {
        return <li>Not found.</li>
      }

      return <li>{contact.FirstName} {contact.LastName} - {contact.PhoneNumber} - {contact.Email}</li>
    });

    return <ul>
      {list}
    </ul>
  }

  function render() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={() => props.close()}
        contentLabel={`Quick Texts`}
        portalClassName="ContactModal"
      >
        <div className="ContactModalHeader">Quick Text</div>

        <div className="ContactModalContent" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <div className="container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className="row">
              <h3 style={{ display: 'inline-block' }}>Send Quick Text</h3>
              <hr />
            </div>
            
            <Bubble width={300} below={true} left={true} helpText="Send a message to all of the contacts listed here. All contacts with a phone number in the list will be sent the same message." style={{ position: 'absolute', right: 0 }} />
            
            <h5>Recipients</h5>
            <div className='row' style={{ paddingLeft: 24 }}>
              { renderContacts() }
            </div>

            <div className='row'>              
              <div className="input-group p-2" style={{ zIndex: 0 }}>
                <textarea className="form-control form-control-sm"  placeholder='Text Message - SMS' value={newMessage} onChange={(e) => setNewMessage(e.target.value)} style={{ width: "calc(100% - 60px)" }} aria-describedby='btnSend' />
                <button className="form-control btn btn-sm btn-primary" id='btnSend' type="button" onClick={sendMessage}><span className="bi-send"></span></button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return render();
}

export default SendQuickText;
import React from 'react';
import CompanyCode from './CompanyCode';
import AdminLogin from './AdminLogin';
import CompanyOptions from './CompanyOptions';
import RuleForm from './RuleForm';
import { useParams } from 'react-router-dom';
import EditAdmins from './EditAdmins';
import Submissions from './Submissions';
import SystemAdmin from './SystemAdmin';
import Nav from '../Nav';
import {
  useCompany,
  useContacts,
  useLoginValidation,
  useMessages,
  useScreens,
  useUser
} from '../Store/storeFunctions';
import ReportIssue from './ReportIssue';
import RequestChange from './RequestChange';
import AccountSettings from './SelfService/AccountSettings';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import {
    getAuth,
    signOut
} from "firebase/auth";
import { initializeApp } from '@firebase/app';
import FormBuilderList from './FormBuilderList';
import { BeatLoader } from 'react-spinners';
import MessagingService from './Messaging/MessagingService';
import ContactService from './Messaging/ContactService';

const auth = getAuth();

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});
const db = getFirestore(app);

function Admin() {
  const { id } = useParams();
  const screens = useScreens();
  const user = useUser();
  const company = useCompany();
  const contacts = useContacts();
  const messages = useMessages();

  const loginValidation = useLoginValidation();
  const [checkedEffect, setCheckedEffect] = React.useState(false);
  const [loadedCompany, setLoadedCompany] = React.useState(false);

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (user?.user && company?.companyData?.code && loadedCompany) {
      // initiate listener for contacts, TODO: add check for the feature to be turned on before doing this.
      const unsub = contacts.loadContacts();
      
      // Return unsubscribe function so that React will unsub for us when the admin component is dropped.
      // the load contacts method above also adds a willClose handler to the window so if the user closes their tab we will unsub.
      return unsub;
    }
  }, [
    user?.user,
    company?.companyData?.code,
    loadedCompany
  ]);

  React.useEffect(() => {
    if (user?.user &&
        company?.companyData?.twilio?.sid &&
        company?.companyData?.twilio?.phoneNumber &&
        company?.companyData?.twilio?.verified &&
        loadedCompany) {
      // initiate listener for messages, TODO: add check for the feature to be turned on before doing this.
      const unsub = messages.loadMessages();
      
      // Return unsubscribe function so that React will unsub for us when the admin component is dropped.
      // the load contacts method above also adds a willClose handler to the window so if the user closes their tab we will unsub.
      return unsub;
    }
  }, [
    user?.user,
    company?.companyData?.twilio?.sid,
    company?.companyData?.twilio?.phoneNumber,
    company?.companyData?.twilio?.verified,
    loadedCompany
  ]);

  async function load() {
    company.setCode(id);

    auth.onAuthStateChanged(async function(userData) {
      if (userData) {
        try {
          var u = await getDoc(doc(db, `/BusinessRules/${id}/Users/${userData.uid}`));
          
          if (u && u.exists()) {
            var data = u.data();
            
            if (data && data.Email?.toLowerCase() == userData.email?.toLowerCase()) {
              // load company data into the store.
              user.set(userData);
              await company.loadCompany(id).then(company.loadAdmins(id));
              setLoadedCompany(true);
            } else {
              loginValidation.set("Invalid username/password.");
              await signOut(auth);
              setLoadedCompany(false);
            }
          } else {
            loginValidation.set("Invalid username/password.");
            await signOut(auth);
            setLoadedCompany(false);
          }
        } catch (ex) {
          loginValidation.set("Invalid username/password.");
          await signOut(auth);
          setLoadedCompany(false);
        }
      } else {
        user.clear();
        setLoadedCompany(false);
      }

      setCheckedEffect(true);
    });
  }

  function goToLogin(c) {
    company.setCode(c);
  }

  function render() {
    if (user?.user && company?.companyData?.code && loadedCompany) { //signed in
      switch (screens.state.currentScreen) {
        case "Rules": return <RuleForm company={company.companyData?.code} />;
        case "Admins": return <EditAdmins />;
        case "Options": return <CompanyOptions />;
        case "Submissions": return <Submissions />;
        case "System": return <SystemAdmin />;
        case "ReportIssue": return <ReportIssue />;
        case "RequestChange": return <RequestChange />;
        case "Settings": return <AccountSettings />;
        case "Forms": return <FormBuilderList />;
        case "Messaging": return <MessagingService />;
        case "Contacts": return <ContactService />;
        default: return <div>Error.</div>;
      }
    } else { //not signed in
      if (!company.companyData?.code) {
        return <CompanyCode goToLogin={goToLogin} />
      } else if (checkedEffect) {
        return <AdminLogin />
      } else {
        return <div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>;
      }
    }
  }

  return <div>
    <Nav />

    <div style={{ height: 'calc(98vh - 175px)' }}> {/*2vh reserved for spacing on each page so they can have background colors*/}
      { render() }
    </div>
  </div>;
}

export default Admin;
import React from 'react';
import { toastPromise } from '../../Helpers/ToastMessage';
import { getFunctions, httpsCallable } from "firebase/functions";

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormikCheckBox, FormikCheckBoxSingle, FormikRadio, FormikTextInput } from '../../FormikFields';
import { useCompany, useUser } from '../../Store/storeFunctions';
import { BeatLoader } from 'react-spinners';
import Bubble from '../../Helpers/Bubble';
import { TwilioComplianceEmbed } from '@twilio/twilio-compliance-embed';

const functions = getFunctions();

const createSubAccount = httpsCallable(functions, "createSubAccount");
const searchAvailablePhoneNumbers = httpsCallable(functions, "searchAvailablePhoneNumbers");
const purchasePhoneNumber = httpsCallable(functions, "purchasePhoneNumber");
const verifyTollFreePhoneNumber = httpsCallable(functions, "verifyTollFreePhoneNumber");
const markTwilioVerificationSubmitted = httpsCallable(functions, "markTwilioVerificationSubmitted");

function Setup(props) {
  const user = useUser();
  const company = useCompany();

  const [loading, setLoading] = React.useState(false);
  const [availableNumbers, setAvailableNumbers] = React.useState();
  const [phoneNumberSearch, setPhoneNumberSearch] = React.useState({
    areaCode: "",
    contains: "",
    region: "",
    searchLocal: false
  });
  const [inquiry, setInquiry] = React.useState(undefined);
  const [step, setStep] = React.useState("getPhoneNumber");

  const PhoneNumberSearchSchema = Yup.object().shape({
    areaCode: Yup.number().typeError("Area Code must include numbers only").min(100).max(999),
    contains: Yup.string(),
    region: Yup.string(),
    searchLocal: Yup.boolean()
  });

  function initiateSubAccount() {
    setLoading(true);
    toastPromise(async () => {
      if (company.companyData.code) {
        const result = await createSubAccount({
          company: company.companyData.code
        });

        if (result.success) {
          await company.assignTwilioSid(result.sid);
        }
      }
    }).then(() => {
      setLoading(false);
    });
  }

  function initiateBringYourOwn() {
    setStep("portNumber")
  }

  function initiateBuyNew() {
    setStep("getPhoneNumber")
  }

  function searchForNumber(searchValues) {
    setLoading(true);
    toastPromise(async () => {
      if (company.companyData.code) {
        const result = await searchAvailablePhoneNumbers(searchValues);

        if (result && result.data && result.data.length) {
          setAvailableNumbers(result.data);
        }
      }
    }, false, "Searching...", "Search Complete!", "Search Failed.").then(() => {
      setLoading(false);
    });
  }

  function obtainPhoneNumber(phoneNumber) {
    setLoading(true);
    toastPromise(async () => {
      if (company.companyData.code && !company.companyData.twilio?.phoneNumber) {
        const result = await purchasePhoneNumber({
          phoneNumber: phoneNumber.phoneNumber,
          company: company.companyData.code
        });

        if (result && result.data?.success && result.data?.phoneNumber) {
          company.assignTwilioPhoneNumber(result.data.phoneNumber);
        }
      }
    }, false, "Obtaining Phone Number...", "Phone Number Obtained!", "Failed to obtain Phone Number.").then(() => {
      setLoading(false);
    });
  }

  function initializeInquiry() {
    setLoading(true);
    toastPromise(async () => {
      if (company.companyData.code) {
        console.log(company.companyData.twilio.phoneNumber);
        const result = await verifyTollFreePhoneNumber({
          company: company.companyData.code,
          phoneNumber: company.companyData.twilio.phoneNumber,
          notificationEmail: user.user.email
        });

        console.log(result);

        if (result && result.data?.success) {
          setInquiry(result.data.inquiry);
        }
      }
    }, false, "Preparing verification...", "Preparation Complete!", "Preparation Failed.").then(() => {
      setLoading(false);
    });
  }

  function isTollFreeNumber(phoneNumber) {
    // Handle empty/null input
    if (!phoneNumber) {
        return false;
    }

    // Clean the number to handle different formats
    let cleanNumber = phoneNumber.replace(/[\s-()]/g, ''); // Remove spaces, dashes, parentheses

    // Handle E164 format (+1) or regular format (1 or no prefix)
    if (cleanNumber.startsWith('+1')) {
        cleanNumber = cleanNumber.substring(2); // Remove +1
    } else if (cleanNumber.startsWith('1')) {
        cleanNumber = cleanNumber.substring(1); // Remove 1
    }

    // Validate that we have at least 10 digits
    if (!/^\d{10,}$/.test(cleanNumber)) {
        return false;
    }

    // Extract area code (first 3 digits)
    const areaCode = cleanNumber.substring(0, 3);

    // List of toll-free area codes in North America
    const tollFreeAreaCodes = [
        '800', // Traditional toll-free
        '888', // Added in 1996
        '877', // Added in 1998
        '866', // Added in 2000
        '855', // Added in 2010
        '844', // Added in 2013
        '833', // Added in 2017
        '822'  // Added in 2022
    ];

    return tollFreeAreaCodes.includes(areaCode);
  }

  function submittedInquiry() {
    toastPromise(async () => {
      if (company.companyData.code) {
        const result = await markTwilioVerificationSubmitted({
          company: company.companyData.code
        });

        if (result.data) {
          await company.loadCompany(company.companyData.code);
        }
      }
    })
  }

  function renderAction() {
    // if we don't have sid then we need to create a sub account.
    if (!company.companyData.twilio?.sid) {
      return <>
        <h3 style={{ display: 'inline' }}>Messaging has not been setup yet for {company.companyData.name}</h3>
        
        <div className='m-3'>
          SMS and MMS messaging is powered by Twilio and some setup is required.
          <br/><br/>
          This page will assist you in getting a phone number configured to send SMS/MMS through Tax Checklist.
        </div>

        <div className='mt-4'>
          <button type="button" className="btn btn-sm btn-primary" onClick={initiateSubAccount}>Get Started</button>
        </div>
      </>
    }

    // if we don't have a phone number then we need to purchase one.
    if (!company.companyData.twilio?.phoneNumber) {
      var header = <h2>Setup your SMS/MMS phone number</h2>;

      switch (step) {
        case "getPhoneNumber": return <>
            {header}

            <div className='container'>
              <div className='row'>
                <div className='col-lg-6'>
                  <Formik
                    initialValues={phoneNumberSearch}
                    validationSchema={PhoneNumberSearchSchema}
                    onSubmit={values => {
                      // same shape as initial values
                      searchForNumber(values);
                      setPhoneNumberSearch(values);
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="mb-3 center">
                          <button className="btn btn-sm btn-primary m-2" type="button" onClick={initiateBringYourOwn}>Bring your own (hosted SMS)</button>
                        </div>

                        <div style={{ width: 300 }}>
                          <Field errors={errors} name="areaCode" component={FormikTextInput} formiklabel="Area Code" />
                        </div>
                        
                        <div style={{ width: 300 }}>
                          <Field errors={errors} name="contains" component={FormikTextInput} formiklabel="Search Pattern">
                            <Bubble helpText="Search pattern for the phone number you would like. You can search with numbers, letters, or * to match any digit. Example: 123***TEST would search for a number with 123 area code that has the last 4 digits of 8378." width={400} />
                          </Field>
                        </div>
                        
                        <div style={{ width: 300 }}>
                          <Field errors={errors} name="region" component={FormikTextInput} formiklabel="State" />
                        </div>

                        <div>
                          <Field errors={errors} name="searchLocal" component={FormikCheckBoxSingle} formiklabel="Local Search" formikinline={true}>
                            <Bubble helpText="Local search instead of Toll Free. If you'd prefer a Toll Free number uncheck this box." width={300} />
                          </Field>
                        </div>

                        <div className="mb-3 center">
                          <button className="btn btn-sm btn-primary m-2" type="submit">Search for a new number</button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>

                <div className='col-lg-6'>
                  <h4>Search Results</h4>

                  <div style={{ overflowY: 'scroll', height: '70vh' }}>
                    {
                      !availableNumbers?.length ? <div> - None -</div> : 
                      availableNumbers.map(n => {
                        var featureKeys = Object.keys(n.capabilities);

                        return <div className='m-2 p-2 row' style={{ border: 'solid black 1px', borderRadius: 5 }}>
                          <div className='col-md-7'>
                            <div>{n.friendlyName}</div>
                            <div>{n.region} - {n.locality}</div>
                          </div>

                          <div className='col-md-5' style={{ display: 'flex', justifyContent: 'right', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                              {
                                featureKeys.map(k => {
                                  return n.capabilities[k] ? <span>{k}</span> : null
                                })
                              }
                            </div>

                            <button className="btn btn-sm btn-primary m-2" type="button" onClick={() => obtainPhoneNumber(n)}>Use this number</button>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </>;
        case "portNumber": return <>
            {header}

            <div className='m-4'>
              This feature allows you to text through Tax Checklist from your landline and/or existing toll free number.
              <br/><br />
              We are currently working on enabling this feature as a self service. If you would like to use this feature before the self service is complete please reach out and we will assist you in getting your phone number ported to your Tax Checklist account.
            </div>

            <div>
              <button type="button" className="btn btn-sm btn-primary" onClick={initiateBuyNew}>Buy a new number</button>
            </div>
          </>;
      }


      return <>
        <h2>Setup Complete</h2>
      </>
    }

    if (!company.companyData.twilio?.verified) {
      var isTollFree = isTollFreeNumber(company.companyData.twilio.phoneNumber);

      // if we are toll free we can do something here.
      if (isTollFree) {
        // if we are not started or started but not submitted then we can do something here.
        if (company.companyData.twilio.verificationStatus !== "Submitted") {
          // if we don't have an inquiry then we need to create one.
          if (!inquiry) {
            return <div>
              You are now set up with your own phone number <strong>{company.companyData.twilio.phoneNumber}</strong>!
              <br /><br />

              You must now register your business with your toll free number to start messaging.
              <br /><br />

              <button type="button" className="btn btn-sm btn-primary" onClick={initializeInquiry}>Begin registration</button>
            </div>
          } else {
            // we have an inquiry so display it!
            return <TwilioComplianceEmbed inquiryId={inquiry.inquiryId} inquirySessionToken={inquiry.inquirySessionToken} onInquirySubmitted={submittedInquiry} />
          }
        } else {
          // if we are submitted then we can do something here.
          return <div>
            You are now set up with your own phone number <strong>{company.companyData.twilio.phoneNumber}</strong>!
            <br /><br />

            We are currently waiting for your registration to be approved. You will receive an email when your registration is approved.
            <br /><br />

            Please contact us if you have any questions or need further assistance.
          </div>
        }
      } else {
        // if we are not toll free we can't do something here.
        return <div>
          You are now set up with your own phone number <strong>{company.companyData.twilio.phoneNumber}</strong>!
          <br /><br />
          
          We are working on enabling self service for A2P 10DLC registration. Please contact us to assist you with registration to begin sending your messages.
        </div>
      }
    }

    // if we are still deciding then show an error screen since we shouldn't ever be at this point.
  }

  function render() {
    if (loading) {
      return <div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>;
    }

    return (
      <div className="container" style={{ marginTop: 10, height: '100%' }}>
        <div className="row" style={{ height: '100%' }}>
          { renderAction() }
        </div>
      </div>
    );
  }

  return render();
}

const styles = {
}

export default Setup;
import { Field, Form, Formik } from "formik";
import React from "react";
import { useParams } from "react-router-dom";
import {
  FormikCheckBox,
  FormikCheckBoxSingle,
  FormikEmailInput,
  FormikButtonSelectInput,
  FormikRadio,
  FormikRadioYN,
  FormikTextAreaInput,
  FormikTextInput,
  FormikAddressInput
} from "../FormikFields";
import * as FormData from "./BuilderTools/FormBuilderData.ts";
import { initializeApp } from '@firebase/app';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import * as CompanyData from "../Helpers/CompanyData.ts";
import {
  validateEmail,
  validateNumber,
  validatePhone,
  validateRequired,
  validateSsn,
  validateYear
} from "../FormikCustomValidations";
import { getFunctions, httpsCallable } from "firebase/functions";
import { BeatLoader } from 'react-spinners';


const app = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});
const functions = getFunctions();
const processSubmission = httpsCallable(functions, "processTaxChecklistSubmission");
const storage = getStorage(app, process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);

function FormTool() {
  // companyid, formid, possible session id for resuming.
  const { ci, fid, sid } = useParams();

  // Page index
  const [pageIndex, setPageIndex] = React.useState(0);

  // Interpolation regex
  const regexp = React.useMemo(() => /<span data-id="([a-zA-Z]+)" data-label="([a-zA-Z -]+)" data-type="mention" class="[a-zA-Z]+_reference">@([a-zA-Z -]+)<\/span>/g, []);

  /**
   * Schema defaults for the form to be rendered.
   * This will be fetched from the db in the end version.
   * When we fetch form the db we will need to not render the formik portion until we have this.
   * Use ci and fid to look up the form.
   * Use sid to look up answers previously done.
   */ 
  const [formModel, setFormModel] = React.useState(undefined);
  const [submission, setSubmission] = React.useState(undefined);
  const [processing, setProcessing] = React.useState(false);
  const [companyData, setCompanyData] = React.useState(undefined);
  const [imageSource, setImageSource] = React.useState();

  React.useEffect(() => {
      if (companyData?.logo) {
          const logoRef = ref(storage, companyData.logo);

          getDownloadURL(logoRef).then(url => {
              setImageSource(url);
          });
      }

  }, [companyData]);

  function stripOffParagraph(output) {
    if (output.startsWith("<p>") && output.endsWith("</p>")) {
      return output.substring(3, output.lastIndexOf("</p>"));
    }
  
    return output;
  }

  const interpolateQuestionValues = React.useCallback(function (question, interpolationValues) {
    const matches = [...question.Text.matchAll(regexp)];

    for (let i = 0; i < matches.length; i++) {
      if (matches[i][1]) {
        question.Text = question.Text.replace(matches[i][0], interpolationValues[matches[i][1]]);
      }
    }

    question.Text = stripOffParagraph(question.Text);

    if (question.HelpText) {
      const helpmatches = [...question.HelpText.matchAll(regexp)];
      
      for (let i = 0; i < helpmatches.length; i++) {
        if (helpmatches[i][1]) {
          question.HelpText = question.HelpText.replace(helpmatches[i][0], interpolationValues[helpmatches[i][1]]);
        }
      }
    }

    return question;
  }, [regexp]);

  const gatherInterpolationData = React.useCallback(async function() {
    var data = await CompanyData.getInterpolationData(ci);

    return data;
  }, [ci]);

  const interpolatePageValues = React.useCallback(function (page, interpolationValues) {
    const matches = [...page.Summary.matchAll(regexp)];

    for (let i = 0; i < matches.length; i++) {
      if (matches[i][1]) {
        page.Summary = page.Summary.replace(matches[i][0], interpolationValues[matches[i][1]]);
      }
    }

    for (let j = 0; j < page.Questions.length; j++) {
      page.Questions[j] = interpolateQuestionValues(page.Questions[j], interpolationValues);
    }

    return page;
  }, [interpolateQuestionValues, regexp]);

  const initiateFormModel = React.useCallback(async function () {
    var data = await FormData.getFormForTool(fid, ci);
    var interpolationValues = await gatherInterpolationData();

    setCompanyData(interpolationValues);

    if (data.Pages) {
      for (let i = 0; i < data.Pages.length; i++) {
        data.Pages[i] = interpolatePageValues(data.Pages[i], interpolationValues.interpolationData);
      }
    }
    
    setFormModel(data);
    setSubmission(data.createSubmission());
  }, [ci, fid, gatherInterpolationData, interpolatePageValues]);

  React.useEffect(() => {
    // on startup of this page, initiate pages.
    initiateFormModel();

    if (sid) {
      console.log(sid);
    }
  }, [initiateFormModel, sid]);

  function moveNext() {
    setPageIndex(pageIndex +1);
    return;
  }

  function compareConditions(conditions, values) {
    var matches = false;

    if (!conditions || conditions.length == 0) {
      return matches;
    }

    var max = Math.max(...conditions.map(c => c.OrGroup ?? 0));

    for (let group = 0; group <= max; group++) {
      // get conditions for this group.
      const groupConditions = conditions.filter(c => c.OrGroup == group || (c.OrGroup == undefined && group == 0));
  
      if (groupConditions.length == 0) {
        continue; // skip this group since there are no conditions.
      }

      // loop group conditions.
      for (let eq = 0; eq < groupConditions.length; eq++) {
        const refEq = groupConditions[eq];
        var leftValue = "";
        var isArray = false;

        if (refEq.QuestionID) {
          var question = values.getQuestion(refEq.QuestionID);

          if (question) {
            if (question.Type == "MULTI") {
              isArray = true;
              leftValue = question.Answer == "" ? [] : question.Answer ?? [];
            } else {
              leftValue = question.Answer ?? "";
            }
          }
        }

        switch (refEq.Comparison) {
          case "EQUAL_TO":
            if (isArray) {
              matches = leftValue.includes(refEq.Value);
            } else {
              matches = leftValue === refEq.Value;
            }
            break;
          case "NOT_EQUAL_TO":
            if (isArray) {
              matches = !leftValue.includes(refEq.Value);
            } else {
              matches = leftValue !== refEq.Value;
            }
            break;
          case "STARTS_WITH":
            if (isArray) {
              matches = leftValue.filter(l => l.startsWith(refEq.Value)).length > 0;
            } else {
              matches = leftValue.startsWith(refEq.Value);
            }
            break;
          case "ENDS_WITH":
            if (isArray) {
              matches = leftValue.filter(l => l.endsWith(refEq.Value)).length > 0;
            } else {
              matches = leftValue.endsWith(refEq.Value);
            }
            break;
          case "CONTAINS":
            if (isArray) {
              matches = leftValue.filter(l => l.includes(refEq.Value)).length > 0;
            } else {
              matches = leftValue.includes(refEq.Value);
            }
            break;
          case "EXCLUDES":
            if (isArray) {
              matches = leftValue.filter(l => !l.includes(refEq.Value)).length > 0;
            } else {
              matches = !leftValue.includes(refEq.Value);
            }
            break;
          case "IS_EMPTY":
            if (isArray) {
              matches = leftValue.length === 0;
            } else {
              matches = leftValue === "";
            }
            break;
          case "IS_NOT_EMPTY":
            if (isArray) {
              matches = leftValue.length > 0;
            } else {
              matches = leftValue !== "";
            }
            break;
          default:
            matches = false;
            break;
        }

        if (!matches) {
          break; // exit loop if we aren't including. i.e. conditions are &&'d with each other
        }
      }
  
      // outside orGroup. If we are true then short circuit. i.e. orGroups are ||'d with the others.
      if (matches) {
        return true;
      }
    }

    return matches;
  }

  function getQuestionIsRequired(question, values) {
    const isVisible = getQuestionIsVisible(question, values);

    if (!isVisible)
      return false;

    if (!question.Required || !question.Required.length)
      return false;

    if (question.Required.some(v => v.Comparison === "ALWAYS"))
      return true;

    if (question.Required.some(v => v.Comparison === "NEVER"))
      return false;

    const isRequired = compareConditions(question.Required, values);

    return isRequired;
  }

  function getQuestionIsVisible(question, values) {
    if (!question.Visibility || !question.Visibility.length)
      return true;

    if (question.Visibility.some(v => v.Comparison === "ALWAYS"))
      return true;

    if (question.Visibility.some(v => v.Comparison === "NEVER"))
      return false;

    const isVisible = compareConditions(question.Visibility, values);

    return isVisible;
  }

  function getQuestionText(text) {
    let txt = text.replace("&nbsp;", " ");

    txt = txt.replace("&amp;", "&");
    txt = txt.replace("&quot;", '"');
    txt = txt.replace("&apos;", "'");
    txt = txt.replace("&lt;", '<');
    txt = txt.replace("&gt;", '>');

    return txt;
  }

  function renderPageFields(values, errors, touched, setFieldValue) {
    let elements = [];

    if (pageIndex < 0 || pageIndex >= formModel.Pages.length) {
      return elements;
    }

    for (let i = 0; i < formModel.Pages[pageIndex].Questions.length; i++) {
      let validationFunctions = [];
      let questions = formModel.Pages[pageIndex].Questions;
      let q = questions[i];

      // map ID to the data structure path to the answer.
      let idx = submission.getQuestionIndex(q.ID);
      let id = `Questions[${idx}].Answer`;
      
      // get visibility and set field props values.
      let visible = getQuestionIsVisible(q, values);

      if (!visible && values.Questions[idx].Answer !== undefined && values.Questions[idx].Answer !== '') {
        if (q.Type == "ADDRESS") {
          console.log(values.Questions[idx].Answer);
          if (values.Questions[idx].Answer !== ";;;;") {
            setFieldValue(id, ";;;;", false);
          }
        } else {
          setFieldValue(id, '', false);
        }
      }

      let fieldProps = {
        errors: errors,
        name: id,
        key: id,
        id: id,
        formikindex: i + 1,
        formiklabel: getQuestionText(q.Text),
        formikhelp: q.HelpText && q.HelpText != "<p></p>" ? q.HelpText : "",
        formikvisible: `${visible}`,
        component: FormikTextInput,
        options: q.Options.map(o => {
          if (typeof o == "string") {
            return o;
          }

          return o.Value;
        }),
      };
      
      // Determine component for this field.
      switch (q.Type) {
        case "NORMAL": fieldProps.component = FormikTextInput; break;
        case "EMAIL": fieldProps.component = FormikEmailInput; break;
        case "FREE": fieldProps.component = FormikTextAreaInput; break;
        case "SINGLE": fieldProps.component = FormikRadio; break;
        case "MULTI": fieldProps.component = FormikCheckBox; break;
        case "SINGLE_BUTTONS":
        case "MULTI_BUTTONS":
          fieldProps.formikmulti = q.Type.startsWith("MULTI");
          fieldProps.formikicon = q.Icon;
          fieldProps.component = FormikButtonSelectInput;
          break;
        case "YESNO": fieldProps.component = FormikRadioYN; break;
        case "CHECK": fieldProps.component = FormikCheckBoxSingle; break;
        case "ADDRESS": fieldProps.component = FormikAddressInput; break;
        default: console.error(`${q.Type} not supported`); break;
      }

      // Determine validation scheme
      let required = getQuestionIsRequired(q, values);

      // check required first since this is the most generic.
      if (required) {
        validationFunctions.push(validateRequired);
      }

      // add in the rest of the validations that are configured.
      for (let v = 0; v < q.Validations.length; v++) {
        switch (q.Validations[v]) {
          case "EMAIL": validationFunctions.push(validateEmail); break;
          case "SSN": validationFunctions.push(validateSsn); break;
          case "PHONE": validationFunctions.push(validatePhone); break;
          case "NUMBER": validationFunctions.push(validateNumber); break;
          case "YEAR": validationFunctions.push(validateYear); break;
          default: console.error(`${q.Validations[v]} not supported`); break;
        } 
      }

      // parent validation function. Calls all the validations that should happen for this field in order
      let validationFunction = (value) => {
        if (!visible) {
          return undefined;
        }

        for (let j = 0; j < validationFunctions.length; j++) {
          let result = validationFunctions[j](value);

          if (result !== undefined) {
            return result;
          }
        }
      }

      // add validate from Formik if we have some requirements on this field.
      if (validationFunctions.length > 0) {
        fieldProps.validate = validationFunction;
      }

      elements.push(<Field {...fieldProps} />);
    }
  
    return elements;
  }

  function renderFormik() {
    return (
      <Formik
        validateOnChange={true}
        initialValues={submission}
        onSubmit={async (values) => {
          setProcessing(true);
          try {
            var results = await processSubmission(values);
            
            if (results.data.Success) {
              window.location = `/FormResult/${ci}/${fid}/${submission.ID}`;
            } else {
              setProcessing(false);
            }
          } catch (ex) {
            console.error(ex);
            setProcessing(false);
          }
          setSubmission(values);
        }}
      >
        {({ values, errors, touched, isValidating, validateForm, setFieldValue }) => (
          
        <div className="card-body">
          <div className="mb-3 center pageSummary">
              <div dangerouslySetInnerHTML={{ __html: formModel.Pages[pageIndex].Summary }}></div>
          </div>

          <hr ></hr>

          <Form>
            <div style={{ maxWidth: 600, margin: "auto", height: '90%' }}>
              { renderPageFields(values, errors, touched, setFieldValue) }
            </div>

            <div className="mb-3 center" style={{ height: 30, display: "flex", bottom: 10, justifyContent: 'space-between' }}>
              <div style={{ width: 100, height: 32, display: 'inline-block' }}>
                {
                  pageIndex === 0 ? null :
                  <button className="btn btn-sm btn-primary"
                    tabIndex={98}
                    style={companyData?.colors?.button ? {
                      borderColor: companyData?.colors?.button,
                      backgroundColor: companyData?.colors?.button
                    } : {}} type="button" onClick={() => {
                      setPageIndex(pageIndex-1);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    Previous
                  </button>
                }
              </div>

              <div style={{ width: 100, height: 32, display: 'inline-block' }}>
                {
                  // if we are at or above the last index, then show submit
                  pageIndex < formModel.Pages.length - 1 ? null :
                  ( processing ? <div className="loadingSpinner"><BeatLoader color={companyData?.colors?.button ?? "#045AA1"} /></div> : <button className="btn btn-sm btn-primary" tabIndex={0} style={companyData?.colors?.button ? {
                    borderColor: companyData?.colors?.button,
                    backgroundColor: companyData?.colors?.button
                  } : {}} type="submit">Submit</button> )
                }
              </div>

              <div style={{ width: 100, height: 32, display: 'inline-block' }}>
                {
                  // if we are at or above the last index, then hide
                  pageIndex >= formModel.Pages.length - 1 ? null :
                  <button className="btn btn-sm btn-primary" type="button"
                  tabIndex={0}
                    style={companyData?.colors?.button ? {
                      borderColor: companyData?.colors?.button,
                      backgroundColor: companyData?.colors?.button
                    } : {}} onClick={() => {
                      validateForm().then(validValue => {
                        if (validValue.Questions === undefined) {
                          // no errors
                          moveNext();
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        } else {
                          // some errors
                          var index = validValue.Questions.findIndex(q => q != undefined && q.Answer);

                          if (index == 0) {
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          } else {
                            var elem = document.getElementById(`Questions[${index-1}].Answer`);
                            elem.scrollIntoView();
                          }
                        }
                      })
                    }}
                  >
                      Next
                  </button>
                }
              </div>
            </div>
          </Form>
        </div>
        )}
      </Formik>
    );
  }

  function render() {
    if (processing) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    var page = null;

    if (submission) {
      page = renderFormik();
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "2%",
          backgroundColor: companyData?.colors?.background ? companyData?.colors?.background : "#FFF",
          height: "100%",
          width: "100%"
        }}
        >
        {
          !imageSource ? null : 
          <img style={{ height: companyData.extraFeatures?.logoSize ?? 150, marginBottom: '2%', margin: 'auto' }} alt="" src={imageSource} />
        }

        <div
          className="card formTool"
          style={{
            maxWidth: "724px",
            width: "100%",
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: companyData?.colors?.accent ? companyData?.colors?.accent : "var(--bs-light-blue)",
            height: '100%',
            margin: 'auto'
          }}
          >
          { page }
        </div>
      </div>
    );
  }

  return render();
}

export default FormTool;

import React from 'react';
import Modal from "react-modal";
import { useCompany } from '../../Store/storeFunctions';
import { toastPromise } from '../../Helpers/ToastMessage';
import { getFunctions, httpsCallable } from "firebase/functions";
/*
  Props shape:
    contactID - number
    close - function
    createNew - bool
    phoneNumber - string - to prepop when new is selected
*/
const functions = getFunctions();
const addAcknowledgement = httpsCallable(functions, "addAcknowledgement");

function AcknowledgeLicenseAgreement(props) {
  const company = useCompany();

  const [acknowledgement, setAcknowledgement] = React.useState({
    acknowledged: false,
    nameEntered: ""
  });

  function accept() {
    if (!acknowledgement.acknowledged || !acknowledgement.nameEntered) {
      return;
    }

    toastPromise(async () => {
      var result = await addAcknowledgement({
        companyId: company.companyData.code,
        nameEntered: acknowledgement.nameEntered
      });

      if (result.data.success) {
        props.accept();
      } else {
        throw Error(result.data.message);
      }
    }, false);
  }

  function render() {
    return (
      <Modal
        isOpen={true}
        onRequestClose={() => props.close()}
        contentLabel={`License Agreement Acceptance`}
        portalClassName="ContactModal"
      >
        <div className="ContactModalHeader">License Agreement Acceptance</div>

        <div className="ContactModalContent">
          <div className="container" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', overflowY: 'auto', flex: 1 }}>
            <div className="container mx-auto px-4 md:px-8 py-6 max-w-4xl pt-5">
              <div style={{ textAlign: 'left' }}>
                <strong>SOFTWARE LICENSE AGREEMENT</strong>
                <p>(the "Agreement")</p><br/>
            
                <p>This Software License Agreement is entered into by and between the following parties:</p><br/>
            
                <p><strong>Licensor:</strong><br/>
                The Tax Checklist<br/>
                My & Ra Holdings, Inc<br/>
                712 E 700 S<br/>
                Clearfield, UT 84015</p><br/>
            
                <p><strong>Licensee:</strong> [Licensee's Full Legal Name and Address]</p><br/>
            
                <p>Effective as of the date of the Licensee's signature below (the "Effective Date"), the Licensor and Licensee agree to the following terms:</p><br/>
            
                <strong>1. License Grant</strong>
                <p>Licensor grants Licensee a non-exclusive, non-transferable license to use the software described in this Agreement (the "Software") in accordance with the terms set forth herein.</p><br/>
            
                <strong>2. Payment Terms</strong><br/>
                
                <strong>2.1. Monthly Payment Amount</strong>
                <p>Licensee agrees to pay Licensor $[specified amount] per month for a period of twelve (12) months.</p><br/>
            
                <strong>2.2. Payment Due Date</strong>
                <p>The first payment is due immediately upon execution of this Agreement. Each subsequent payment is due on the same day of each calendar month thereafter.</p><br/>
            
                <strong>2.3. Early Termination by Licensee</strong>
                <p>If Licensee terminates this Agreement before the twelfth (12th) and final payment, all remaining payments become due immediately in full.</p><br/>
            
                <strong>2.4. Prepayment Option</strong>
                <p>Licensee may prepay the total of all remaining monthly payments at any time without penalty.</p><br/>
            
                <strong>2.5. Late Payment Fees</strong>
                <p>If a payment is not received by the due date, Licensee will incur a late fee of $25 plus 1.5% of the past-due amount per month (equivalent to 18% annually).</p><br/>
            
                <strong>2.6. Automatic Payment Authorization</strong>
                <p>By signing this Agreement, Licensee authorizes Licensor to automatically process payments using the payment method provided by Licensee.</p><br/>
            
                <strong>3. Electronic Signatures</strong>
                <p>Both parties agree that electronic signatures are legally binding and enforceable under the terms of this Agreement in accordance with applicable laws, including the Utah Uniform Electronic Transactions Act.</p><br/>
            
                <strong>4. Default and Collection Costs</strong>
                <p>In the event of default by Licensee, Licensee agrees to pay all collection costs, including court costs, attorneys' fees, and any other expenses incurred by Licensor to enforce the terms of this Agreement.</p><br/>
            
                <strong>5. Term and Termination</strong>
                <p>This Agreement begins on the Effective Date and continues until the Licensee has made all twelve (12) payments, unless terminated earlier as permitted herein.</p><br/>
            
                <strong>6. Governing Law</strong>
                <p>This Agreement shall be governed by and construed in accordance with the laws of the state of Utah.</p><br/>
            
                <strong>7. Entire Agreement</strong>
                <p>This Agreement constitutes the entire understanding between the parties and supersedes all prior agreements, whether oral or written. Any amendments to this Agreement must be made in writing and signed by both parties.</p><br/>
            
                <strong>8. Severability</strong>
                <p>If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p><br/>
            
                <strong>9. Acknowledgment</strong>
                <p>By signing this Agreement, Licensee acknowledges that they have read, understood, and agree to be bound by its terms.</p><br/>
            
                <p><strong>IN WITNESS WHEREOF</strong>, the parties have executed this Agreement as of the Effective Date.</p><br/>
            
                <p><strong>Licensor</strong><br/>
                Name: ___________________________<br/>
                Signature: _______________________<br/>
                Date: ____________________________</p><br/>
            
                <p><strong>Licensee</strong><br/>
                Name: ___________________________<br/>
                Signature: _______________________<br/>
                Date: ____________________________</p><br/>
              </div>
            </div>

            <div className="mb-4 p-4" style={{ fontSize: 16 }}>              
              <div className="form-check mb-3">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="acceptAgreement"
                  checked={acknowledgement?.acknowledged || false}
                  onChange={(e) => {
                    setAcknowledgement(prev => ({
                      ...prev,
                      acknowledged: e.target.checked
                    }));
                  }}
                />
                <label className="form-check-label" htmlFor="acceptAgreement">
                  I accept the Software License Agreement
                </label>
              </div>

              <div className="form-group">
                <label htmlFor="agreementName">Please type your full name to accept:</label>
                <input 
                  type="text"
                  className="form-control"
                  id="agreementName"
                  value={acknowledgement?.nameEntered || ''}
                  onChange={(e) => {
                    setAcknowledgement(prev => ({
                      ...prev,
                      nameEntered: e.target.value
                    }));
                  }}
                  placeholder="Enter your full name"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="ContactModalFooter" style={{ 
          borderTop: '1px solid #dee2e6',
          padding: '1rem',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '0.5rem'
        }}>
          <button
            className="btn btn-secondary"
            onClick={() => props.close()}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={accept}
            disabled={!acknowledgement.acknowledged || !acknowledgement.nameEntered}
          >
            Accept
          </button>
        </div>
      </Modal>
    );
  }

  return render();
}

const styles = {
}

export default AcknowledgeLicenseAgreement;
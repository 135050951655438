import { initializeApp } from '@firebase/app';
import { getFirestore, getDoc, doc, query, collection, getDocs, setDoc, onSnapshot, orderBy, limit } from '@firebase/firestore';
import { toastPromise } from '../Helpers/ToastMessage';
import { Contact, Contacts, parseContactsFromObject } from '../Admin/Messaging/Contacts';
import * as MessagingTools from '../Admin/Messaging/Messages';

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  });
  
const db = getFirestore(app);

const COMPANY_PATH = 'BusinessRules';

export async function getInterpolationData(company: string): Promise<any> {
    return await toastPromise(
        async ()=>{
            // grab system level forms.
            var rtnObj = {
              colors: {},
              logo: "",
              extraFeatures: {
                logoSize: 150,
              },
              interpolationData: {}
            };
            var ref = await getDoc(doc(db, `${COMPANY_PATH}/${company}`));

            if (ref && ref.exists()) {
                var data = ref.data();

                if (data.interpolationData) {
                  rtnObj.interpolationData = data.interpolationData;
                }

                rtnObj.colors = {
                  background: data.primaryColor,
                  accent: data.secondaryColor,
                  button: data.tertiaryColor
                };
                rtnObj.logo = data.logoPath;
                rtnObj.extraFeatures.logoSize = data.extraFeatures?.logoSize ?? 150
            }

            return rtnObj;
        },
        true
    );
}

// Pushes a single array of contacts through the Contacts data structure to paginate all the contacts.
export async function saveCompanyContacts(company: string, contacts: Contact[]): Promise<void> {
  return await toastPromise(
    async ()=> {
      var saveContacts = new Contacts();
      contacts.sort((l, r) => {
        if (l.LastName < r.LastName) return -1;
        if (l.LastName > r.LastName) return 1;
        if (l.FirstName < r.FirstName) return -1;
        if (l.FirstName > r.FirstName) return 1;
        return 0;
      });

      for (let i = 0; i < contacts.length; i++) {
        saveContacts.add(contacts[i]);
      }

      let docCount = saveContacts.docCount();

      for (let i = 0; i < docCount; i++) {
        var dataToSave = saveContacts.getContactListForSaving(i);

        await setDoc(doc(db, `/BusinessRules/${company}/Contacts/${i}`), dataToSave);
      }
    },
    true
  );
}

// sets up a watch on the contacts and returns the unsubscribe method.
export function watchCompanyMessages(company: string, callback: Function): any {
  // Query by earliest message newest to oldest and get the most recent 2 docs.
  var currentQuery = query(
    collection(db, `/BusinessRules/${company}/Messages`),
    orderBy("DateTime", "desc"),
    limit(2)
  );

  const unsub = onSnapshot(currentQuery, async (querySnapshot) => {
    return await toastPromise(
      async ()=>{
        let messages = new MessagingTools.Messages();
        
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            var messageData = doc.data();
            
            MessagingTools.addMessagesFromObject(messages, messageData);
          }
        });
        
        callback(messages);
      },
      false, "Loading new Messages...", "Messages loaded", "Messages failed to load."
    );
  });

  return unsub;
}

// sets up a watch on the contacts and returns the unsubscribe method.
export function watchCompanyContacts(company: string, callback: Function): any {
  var currentQuery = query(collection(db, `/BusinessRules/${company}/Contacts`));
  const unsub = onSnapshot(currentQuery, async (querySnapshot) => {
    return await toastPromise(
      async ()=>{
        var contacts: Contact[] = [];
        
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            var userData = doc.data();
            var newContacts = parseContactsFromObject(userData);
            
            for (let i = 0; i < newContacts.contactCount(); i++) {
              let newContact = newContacts.at(i);
              
              if (newContact) {
                contacts.push(newContact);
              }
            }
          }
        });
        
        callback(contacts);
      },
      false, "Loading new contacts...", "Contacts loaded", "Contacts failed to load."
    );
  });

  return unsub;
}

// Returns a single array that represents all of the contacts.
export async function getCompanyContacts(company: string): Promise<Contact[]> {
  return await toastPromise(
    async ()=>{
      var currentQuery = query(collection(db, `/BusinessRules/${company}/Contacts`));
      
      var contacts: Contact[] = [];
      var contactPages = await getDocs(currentQuery);
      
      contactPages.forEach((doc) => {
        if (doc.exists()) {
          var userData = doc.data();
          var newContacts = parseContactsFromObject(userData);

          for (let i = 0; i < newContacts.contactCount(); i++) {
            let newContact = newContacts.at(i);

            if (newContact) {
              contacts.push(newContact);
            }
          }
        }
      });

      return contacts;
    },
    true
  );
}

export async function getCompanyAdmins(company: string): Promise<any[]> {
  return await toastPromise(
    async ()=>{
      var currentQuery = query(collection(db, `/BusinessRules/${company}/Users`));
      
      let users: any[] = [];
      var currentUsers = await getDocs(currentQuery);
      
      currentUsers.forEach((doc) => {
        if (doc.exists()) {
          var userData = doc.data();

          users.push(userData);
        }
      });

      return users;
    },
    true
  );
}

export async function getCompanyData(company: string): Promise<any> {
  return await toastPromise(
    async ()=>{
      // grab system level forms.
      var c = await getDoc(doc(db, `/BusinessRules/${company}`));

      if (c && c.exists()) {
        var data = c.data();

        const co = {
          email: data.email ?? '',
          emailKey: data.emailKey ?? '',
          emailProvider: data.emailProvider ?? 'gmail',
          emailReply: data.emailReply ?? '',
          name: data.name ?? '',
          emailChecklistStyle: data.emailChecklistStyle ?? '',
          logoPath: data.logoPath ?? '',
          primaryColor: data.primaryColor ?? '',
          secondaryColor: data.secondaryColor ?? '',
          tertiaryColor: data.tertiaryColor ?? '',
          stripeCustomerId: data.stripeCustomerId ?? '',
          yearlyAllotmentProgress: data.yearlyAllotmentProgress ?? 0,
          stripeSubscription: data.stripeSubscription ?? '',
          messagingSubscription: data.messagingSubscription ?? '',
          stripeSubscriptionStatus: data.stripeSubscriptionStatus ?? '',
          isSubscribed: data.isSubscribed ?? false,
          interpolationData: {
            CompanyName: data.interpolationData?.CompanyName ?? '',
            CompanyEmail: data.interpolationData?.CompanyEmail ?? '',
            CompanyWebsite: data.interpolationData?.CompanyWebsite ?? '',
            CompanyPortal: data.interpolationData?.CompanyPortal ?? '',
            CompanyPhone: data.interpolationData?.CompanyPhone ?? '',
            CompanyAddress: data.interpolationData?.CompanyAddress ?? ''
          },
          extraFeatures: {
            sharableStatus: data.extraFeatures?.sharableStatus ?? false,
            logoSize: data.extraFeatures?.logoSize ?? 150,
            assignAdmins: data.extraFeatures?.assignAdmins ?? false,
            messaging: data.extraFeatures?.messaging ?? false
          },
          twilio: {
            sid: data.twilio?.sid ?? "",
            phoneNumber: data.twilio?.phoneNumber ?? "",
            verified: data.twilio?.verified ?? false,
            verificationStatus: data.twilio?.verificationStatus ?? "",
            verificationDescription: data.twilio?.verificationDescription ?? ""
          }
        }

        return co;
      }

      return null;
    },
    true
  );
}
import { initializeApp } from '@firebase/app';
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  doc,
  where,
  query,
  limit
} from '@firebase/firestore';
import { toastPromise } from '../../Helpers/ToastMessage';
import { Form, Page } from '../Form';

const app = initializeApp({
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  });
  
const db = getFirestore(app);

const SYSTEM_FORMS = 'SystemForms';
const BUSINESS_RULES = 'BusinessRules';
const FORMS = 'Forms';
const SYSTEM_ID = 'tcpSystemAdministration';

export async function getNewForm(company: string): Promise<Form> {
    var form = new Form("New Form", "INDIVIDUAL");

    form.CompanyID = company;

    if (company != SYSTEM_ID) {
      form.addPage(new Page("Additional Details"));
    }

    return form;
}

function getDataAsForm(company: string, data: any): Form  {
    const dataCopy = new Form("New Form", "INDIVIDUAL");
    const copy = new (dataCopy.constructor as { new (): Form })();
        
    Object.assign(copy, data);

    copy.CompanyID = company;

    return copy;
}

export async function getForms(company: string, active: boolean): Promise<Form[]> {
    return await toastPromise(
        async ()=>{
            if (company == SYSTEM_ID) { // grab system level forms.
                var sysQuery = query(collection(db, `${SYSTEM_FORMS}`), where("Active", "==", active));
                var sysRef = await getDocs(sysQuery);

                if (sysRef && sysRef.size > 0) {
                    return sysRef.docs.map(ref => getDataAsForm(company, ref.data()));
                } else {
                    return [];
                }
            } else { // grab company level forms.
                var compQuery = query(collection(db, `${BUSINESS_RULES}/${company}/${FORMS}`), where("Active", "==", active));
                var compRef = await getDocs(compQuery);

                if (compRef && compRef.size > 0) {
                    return compRef.docs.map(ref => getDataAsForm(company, ref.data()));
                } else {
                    return [];
                }
            }
        },
        true
    );
}

export async function getForm(formId: string, company: string): Promise<Form> {
    return await toastPromise(
        async ()=>{
            if (company == SYSTEM_ID) { // grab system level forms.
                var sysRef = await getDoc(doc(db, `${SYSTEM_FORMS}/${formId}`));

                if (sysRef && sysRef.exists()) {
                    return getDataAsForm(company, sysRef.data());
                }
            } else { // grab company level forms.
                var compRef = await getDoc(doc(db, `${BUSINESS_RULES}/${company}/${FORMS}/${formId}`));

                if (compRef && compRef.exists()) {
                    return getDataAsForm(company, compRef.data());
                }
            }

            return {};
        },
        true
    );
}

export async function getFormByType(type: string, company: string): Promise<Form | null> {
  return await toastPromise(
    async ()=>{
      var compQuery = query(
        collection(db, `${BUSINESS_RULES}/${company}/${FORMS}`),
        where("Active", "==", true),
        where("Type", "==", type),
        limit(1)
      );
      var compRef = await getDocs(compQuery);

      if (compRef && compRef.size > 0) {
          return getDataAsForm(company, compRef.docs[0].data());
      } else {
          return null;
      }
    },
    true
  );
}

export async function getFormForTool(formId: string, company: string): Promise<Form> {
  return await toastPromise(
    async () => {
      // First get the system form to get the type
      var systemForm = await getForm(formId, SYSTEM_ID);
      
      if (!systemForm || !systemForm.Type) {
        throw new Error("System form not found or type not defined");
      }

      // Get the company form using the type from system form
      var companyForm = await getFormByType(systemForm.Type, company);
      
      if (companyForm?.DisableInheritance) {
        // If inheritance is disabled, return the company form
        return companyForm;
      }

      // If no company form or inheritance is not disabled,
      // proceed with the original inheritance logic
      const copy = new (systemForm.constructor as { new (): Form })();
      Object.assign(copy, systemForm);
      copy.CompanyID = company;

      if (companyForm) {
        copy.Name = companyForm.Name;

        if (companyForm.Pages?.length > 0) {
          for (let i = 0; i < companyForm.Pages?.length; i++) {
            if (companyForm.Pages[i].Questions?.length > 0) {
              copy.addPage(companyForm.Pages[i]);
            }
          }
        }
      }

      return copy;
    },
    true
  );
}

export async function setForm(form: Form, company: string): Promise<void> {
    return await toastPromise(
        async ()=>{
            const formToSave = form.getJsVersionForSaving();

            if (company == "tcpSystemAdministration") { // set system level forms.
                await setDoc(doc(db, `/${SYSTEM_FORMS}/${form.ID}`), formToSave);
            } else { // grab company level forms.
                await setDoc(doc(db, `/${BUSINESS_RULES}/${company}/${FORMS}/${form.ID}`), formToSave);
            }
        },
        false
    );
}
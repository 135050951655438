import React from 'react';
import { useContacts } from '../../Store/storeFunctions';
import { AgGridReact } from 'ag-grid-react';
import { ViewDetailsCellRenderer } from '../../Helpers/AgGridCellRenderers';
import { useCSVDownloader } from 'react-papaparse';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import ContactDetails from './ContactDetails';
import ContactImport from './ContactImport';
import SendQuickText from './SendQuickText';

function ContactService() {
  const [selectedContact, setSelectedContact] = React.useState();
  const [createNew, setCreateNew] = React.useState();
  const [importContacts, setImportContacts] = React.useState();
  const { CSVDownloader, Type } = useCSVDownloader();
  
  const [gridHeight, setGridHeight] = React.useState();
  const gridRef = React.useRef();

  const [quickTextContacts, setQuickTextContacts] = React.useState();
  const [hasSelectedRows, setHasSelectedRows] = React.useState();

  const contacts = useContacts();

  React.useEffect(() => {
     window.addEventListener('resize', updateSize);
     updateSize();
  }, []);

  function updateSize() {
    setGridHeight(window.innerHeight - 325);
  }

  const columnDefs = [
    {
      field: 'FirstName',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "First Name",
      cellRenderer: ViewDetailsCellRenderer,
      cellEditorParams: {
        viewDetails: viewContactDetails,
        iconTooltip: "View Contact"
      }
    },
    {
      field: 'LastName',
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Last Name",
      lockVisible: true
    },
    {
      field: 'PhoneNumber',
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Phone Number.",
      lockVisible: true
    },
    {
      field: 'Email',
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Email address."
    },
    {
      field: 'Notes',
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Notes."
    },
    {
      field: 'ID',
      resizable: true,
      sortable: true,
      filter: true,
      headerTooltip: "Unique contact id",
    }
  ];

  function viewContactDetails(id) {
    if (contacts.contacts && id) {
      setSelectedContact(id);
    }
  }

  function createNewContact() {
    setCreateNew(true);
  }

  function importNewContacts() {
    setImportContacts(true);
  }

  function closeViewContact() {
    setSelectedContact(undefined);
    setCreateNew(false);
    setImportContacts(false);
  }

  function sendQuickTexts() {
    var rows = gridRef.current.api.getSelectedRows();

    if (rows && rows.length > 0) {
      var ids = rows.map(r => r.ID);
      setQuickTextContacts(ids);
    }
  }

  function closeQuickTexts() {
    setQuickTextContacts(undefined);
  }

  function selectionChanged() {
    var rows = gridRef.current.api.getSelectedRows();
    setHasSelectedRows(rows.length > 0);
  }

  function render() {
    return (
      <div className="container-fluid" style={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '2vh' }}>
        <div className="row">
          <div className='col-12' style={{ marginBottom: 8, marginTop: 8, display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
            <button className="btn btn-sm btn-primary" disabled={!hasSelectedRows} type='button' onClick={sendQuickTexts}>Send Quick Text</button>
            <button className="btn btn-sm btn-primary" type='button' onClick={createNewContact}>Create New Contact</button>
            <button className="btn btn-sm btn-primary" type='button' onClick={importNewContacts}>Import Contacts (CSV)</button>

            <CSVDownloader
              type={Type.Button}
              filename={'Tax-Checklist-Contacts'}
              data={() => {
                var data = "FirstName,LastName,PhoneNumber,Email,Notes\r\n";

                contacts?.contacts?.map(c => {
                  data += `${c.FirstName},${c.LastName},${c.PhoneNumber},${c.Email},${c.Notes}\r\n`;
                });

                return data;
              }}
              className="btn btn-sm btn-primary"
            >
              Download (CSV)
            </CSVDownloader>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div style={{ height: gridHeight, width: '100%', position: 'relative', marginTop: 12 }} className="ag-theme-balham">
              <AgGridReact
                ref={gridRef}
                rowData={contacts.contacts}
                columnDefs={columnDefs}
                suppressRowClickSelection={true}
                enableCellTextSelection={true}
                suppressMenuHide={true}
                onSelectionChanged={selectionChanged}
                rowSelection="multiple">
              </AgGridReact>
            </div>
          </div>
        </div>

    
        { selectedContact == undefined ? null : <ContactDetails contactID={selectedContact} close={closeViewContact} /> }
        { !createNew ? null : <ContactDetails createNew={createNew} close={closeViewContact} /> }
        { !importContacts ? null : <ContactImport close={closeViewContact} /> }
        { !quickTextContacts ? null : <SendQuickText contacts={quickTextContacts} close={closeQuickTexts} /> }
      </div>
    );
  }

  return render();
}

export default ContactService;
import * as Store from "./store";
import * as CompanyData from "../Helpers/CompanyData";

export const useLoginValidation = () => {
    const {state, dispatch} = Store.useStore();
    return {
        error: state.loginError,
        set: (err) => dispatch({ data: err, type: Store.ACTION_TYPE_AUTHENTICATION_ERROR}),
        clear: () => dispatch({ data: null, type: Store.ACTION_TYPE_AUTHENTICATION_ERROR})
    }
}

export const useUser = () => {
    const {state, dispatch} = Store.useStore();
    return {
        user: state.user,
        set: (user) => dispatch({ data: user, type: Store.ACTION_TYPE_AUTHENTICATION}),
        clear: () => dispatch({ data: null, type: Store.ACTION_TYPE_AUTHENTICATION})
    }
}

export const useScreens = () => {
    const {state, dispatch} = Store.useStore();
    return {
        state: {
            currentScreen: state.currentScreen,
            currentSubScreen: state.currentSubScreen,
            currentSubScreens: state.currentSubScreens,
            navOptions: state.navOptions
        },
        set: (newScreen) => {
            dispatch({ data: { current: "", screens: [] }, type: Store.ACTION_TYPE_SUB_SCREEN});
            dispatch({ data: newScreen, type: Store.ACTION_TYPE_SCREEN});
        },
        setSubScreen: (screen) => {
            var changes = { current: screen, screens: [] };

            if (state.currentSubScreens?.length) {
                var index = state.currentSubScreens.findIndex(v => v.content === screen.content && v.type === screen.type);
                
                if (index > -1) {
                    changes.current = state.currentSubScreens[index];
                    changes.screens = state.currentSubScreens.slice(0, index+1);
                } else {
                    changes.screens = [...state.currentSubScreens, screen];
                }
            } else {
                changes.screens.push(screen);
            }
            
            dispatch({ data: changes, type: Store.ACTION_TYPE_SUB_SCREEN});
        },
        clearSubScreens: () => {
            dispatch({ data: { current: undefined, screens: [] }, type: Store.ACTION_TYPE_SUB_SCREEN});
        },
        clear: () => {
            dispatch({ data: { current: "", screens: [] }, type: Store.ACTION_TYPE_SCREEN});
            dispatch({ data: "", type: Store.ACTION_TYPE_SCREEN});
        }
    }
}

export const useCompany = () => {
    const {state, dispatch} = Store.useStore();
    return {
        companyData: state.companyData,
        admins: state.admins,
        setCode: (company) => {
          dispatch({ data: company, type: Store.ACTION_TYPE_COMPANY });
        },
        clear: () => {
          dispatch({ data: null, type: Store.ACTION_TYPE_COMPANY_DATA });
        },
        loadCompany: async (company) => {
          console.log("loading company")
          const code = company ?? state.companyData?.code;

          if (code) {
            const data = await CompanyData.getCompanyData(code);
            
            dispatch({ data: {
              code: code,
              ...data
            }, type: Store.ACTION_TYPE_COMPANY_DATA });
          }
        },
        loadAdmins: async (company) => {
          const code = company ?? state.companyData?.code;

          if (code) {
            const data = await CompanyData.getCompanyAdmins(code);
            
            dispatch({ data: {
              code: code,
              admins: data,
            }, type: Store.ACTION_TYPE_COMPANY_ADMINS_DATA });
          }
        },
        assignTwilioSid: async (sid) => {
          const companyData = {
            ...state.companyData,
            twilio: {
              ...state.companyData.twilio,
              sid: sid
            }
          };
            
          dispatch({ data: { ...companyData }, type: Store.ACTION_TYPE_COMPANY_DATA });
        },
        assignTwilioPhoneNumber: async (phoneNumber) => {
          const companyData = {
            ...state.companyData,
            twilio: {
              ...state.companyData.twilio,
              phoneNumber: phoneNumber
            }
          };
            
          dispatch({ data: { ...companyData }, type: Store.ACTION_TYPE_COMPANY_DATA });
        }
    }
}

export const useMessages = () => {
    const {state, dispatch} = Store.useStore();
    return {
        messages: state.messages,
        clear: () => {
          if (state.messageWatch) {
            state.messageWatch();
          }

          dispatch({ data: {}, type: Store.ACTION_TYPE_MESSAGES});
          dispatch({ data: null, type: Store.ACTION_TYPE_MESSAGE_WATCH });
        },
        loadMessages: (company) => {
          if (!state.companyData?.extraFeatures?.messaging) {
            return () => {}; // return dummy function
          }

          const code = company ?? state.companyData?.code;
          const watchCallback = (newMessages) => {
            console.log("Messages Loaded", newMessages);
            
            dispatch({ data: newMessages, type: Store.ACTION_TYPE_MESSAGES });
          }

          if (!code) {
            dispatch({ data: {}, type: Store.ACTION_TYPE_MESSAGES });
          } else if (!state.messageWatch) {
            const unsub = CompanyData.watchCompanyMessages(code, watchCallback);

            // add listener as soon as we can so if the user closes the tab or reloads the page we don't keep this up.
            window.addEventListener("beforeunload", (ev) => {
              if (unsub) {
                // removing listener
                unsub();
              }
            });
            
            dispatch({ data: unsub, type: Store.ACTION_TYPE_MESSAGE_WATCH });
            return unsub;
          } else {
            console.log("Listener already added.");
          }

          return state.messageWatch;
        }
    }
}

export const useContacts = () => {
    const {state, dispatch} = Store.useStore();
    return {
        contacts: state.contacts,
        clear: () => {
          if (state.contactWatch) {
            state.contactWatch();
          }
          
          dispatch({ data: {}, type: Store.ACTION_TYPE_CONTACTS});
          dispatch({ data: null, type: Store.ACTION_TYPE_CONTACT_WATCH });
        },
        loadContacts: (company) => {
          if (!state.companyData?.extraFeatures?.messaging) {
            return () => {}; // return dummy function
          }

          const code = company ?? state.companyData?.code;
          const watchCallback = (newContacts) => {
            console.log("Contacts Loaded", newContacts);
            
            dispatch({ data: newContacts, type: Store.ACTION_TYPE_CONTACTS });
          }

          if (!code) {
            dispatch({ data: [], type: Store.ACTION_TYPE_CONTACTS });
          } else if (!state.contactWatch) {
            const unsub = CompanyData.watchCompanyContacts(code, watchCallback);

            // add listener as soon as we can so if the user closes the tab or reloads the page we don't keep this up.
            window.addEventListener("beforeunload", (ev) => {
              if (unsub) {
                // removing listener
                unsub();
              }
            });
            
            dispatch({ data: unsub, type: Store.ACTION_TYPE_CONTACT_WATCH });
            return unsub;
          } else {
            console.log("Listener already added.");
          }

          return state.contactWatch;
        }
    }
}
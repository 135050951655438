import React from 'react';
import Modal from "react-modal";
import { useCompany, useContacts } from '../../Store/storeFunctions';
import { toastPromise } from '../../Helpers/ToastMessage';
import { v4 as uuidv4 } from "uuid";
import { saveCompanyContacts } from '../../Helpers/CompanyData';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { FormikTextAreaInput, FormikTextInput } from '../../FormikFields';
/*
  Props shape:
    contactID - number
    close - function
    createNew - bool
    phoneNumber - string - to prepop when new is selected
*/

function ContactDetails(props) {
  const [contact, setContact] = React.useState();

  const companyStore = useCompany();
  const contactStore = useContacts();

  const ContactSchema = Yup.object().shape({
    FirstName: Yup.string(),
    LastName: Yup.string(),
    Email: Yup.string(),
    PhoneNumber: Yup.string().required('Required'),
    ID: Yup.string(),
    Notes: Yup.string()
  });

  React.useEffect(() => {
    setContact(getInitialContact());
  }, []);

  function getInitialContact() {
    if (props.createNew) {
      var newContact = {
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNumber: props.phoneNumber || "",
        Notes: "",
        ID: uuidv4()
      };

      return newContact;
    } else if (props.contactID) {
      var oldContact = contactStore.contacts.find(c => c.ID == props.contactID);

      return oldContact;
    }
  }

  function saveContact(editedContact) {
    toastPromise(async () => {
      let dataToSave = [];
      if (props.createNew) {
        dataToSave = [ ...contactStore.contacts, editedContact];

        await saveCompanyContacts(companyStore.companyData.code, dataToSave);
      } else {
        dataToSave = [ ...contactStore.contacts ];

        var index = contactStore.contacts.findIndex(c => c.ID == props.contactID)
        dataToSave[index] = editedContact;

        await saveCompanyContacts(companyStore.companyData.code, dataToSave);
      }

      props.close();
    }, false);
  }

  function render() {
    if (!contact) {
      return null
    }

    return (
      <Modal
        isOpen={true}
        onRequestClose={() => props.close()}
        contentLabel={`Contact Details`}
        portalClassName="ContactModal"
      >
        <div className="ContactModalHeader">Contact Details</div>

        <div className="ContactModalContent" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <div className="container" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className="row">
              <h3>Basic Information</h3><hr />
            </div>

            <div className='row'>              
              <Formik
                initialValues={contact}
                validationSchema={ContactSchema}
                onSubmit={values => {
                  // same shape as initial values
                  setContact(values);
                  saveContact(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className='row'>
                      <div className='col-md-6'><Field errors={errors} name="FirstName" component={FormikTextInput} formiklabel="First Name" /></div>
                      <div className='col-md-6'><Field errors={errors} name="LastName" component={FormikTextInput} formiklabel="Last Name" /></div>
                    </div>

                    <div className='row'>
                      <div className='col-md-6'><Field errors={errors} name="PhoneNumber" component={FormikTextInput} formiklabel="Phone" /></div>
                      <div className='col-md-6'><Field errors={errors} name="Email" component={FormikTextInput} formiklabel="Email" /></div>
                    </div>

                    <Field errors={errors} name="Notes" component={FormikTextAreaInput} formiklabel="Notes" />

                    <div className="mb-3 center"> 
                      <button className="btn btn-sm btn-primary" type="submit">Save</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return render();
}

const styles = {
}

export default ContactDetails;
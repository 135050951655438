import { Comparisons } from "../Form/Form";

export class ViewDetailsCellRenderer {
  init(params) {
    this.params = params;

    this.eGui = document.createElement('div');
    this.eGui.style = "display: flex; align-items: center";

    var icon = document.createElement("span");
    icon.className = "bi bi-zoom-in";
    icon.addEventListener('click', function () {
      console.log(params);
      params.colDef.cellEditorParams.viewDetails(params.value.id || params.data.ID);
    });
    icon.title = params.colDef.cellEditorParams.iconTooltip || "View Submission Details";
    icon.style = "cursor: pointer; font-size: 18px; padding-right: 4px;";

    var text = document.createElement("div");
    text.innerText = params.value.value ? params.value.value : params.value;
    text.style = "display: inline-block;";

    this.eGui.append(icon);
    this.eGui.append(text);
  }

  getGui() {
    return this.eGui;
  }
}

export class AgeCellRenderer {
  init(params) {
    this.params = params;
    this.eGui = document.createElement('div');
    
    var newColor = "var(--green)";
    var midColor = "var(--yellow)";
    var oldColor = "var(--red)";


    this.eGui.title = `${params.value} day(s) since last update.`;

    if (params.value == null) {
      this.eGui.setAttribute("style", `background-color: ${newColor}; height: 15px; width: 15px; border-radius: 7px; margin: 4px;`);
      this.eGui.title = `Completed.`;
    } else if (params.value < 14) {
      this.eGui.setAttribute("style", `background-color: ${newColor}; height: 15px; width: 15px; border-radius: 7px; margin: 4px;`);
    } else if (params.value < 21) {
      this.eGui.setAttribute("style", `background-color: ${midColor}; height: 15px; width: 15px; border-radius: 7px; margin: 4px;`);
    } else {
      this.eGui.setAttribute("style", `background-color: ${oldColor}; height: 15px; width: 15px; border-radius: 7px; margin: 4px;`);
    }
  }

  getGui() {
    return this.eGui;
  }
}

export class StaticLinkRenderer {	
    init(params) {
      this.params = params;

      var icon = document.createElement('span');

      icon.className = "bi bi-link-45deg";
      icon.addEventListener('click', function () {
        navigator.clipboard.writeText(params.value);
        alert("Static link copied!");
      });
      icon.title = "Copy to clipboard";
      icon.style = "cursor: pointer; font-size: 22px; padding-right: 4px";

      var iconQrCode = document.createElement('span');

      iconQrCode.className = "bi bi-qr-code";
      iconQrCode.addEventListener('click', function () {
        params.colDef.cellEditorParams.generateQrCode(params.value);
      });
      iconQrCode.title = "View QR Code";
      iconQrCode.style = "cursor: pointer; font-size: 22px; padding-right: 4px";

      this.eGui = document.createElement('span');
      this.eGui.style = "display: inline-flex;";
      
      this.eGui.innerText = params.value;
      this.eGui.prepend(iconQrCode);
      this.eGui.prepend(icon);
    }
    
    getGui() {
      return this.eGui;
    }
}

export class YesNoCellRenderer {	
    init(params) {
      this.params = params;

      this.eGui = document.createElement('span');

      if (params.value === "NO") {
        this.eGui.setAttribute("style", "color: var(--red)");
      } else if (params.value === "YES") {
        this.eGui.setAttribute("style", "color: green");
      }
      
      this.eGui.innerText = params.value;
    }
    
    getGui() {
      return this.eGui;
    }
}

export class FullWidthCellRenderer {
  init(params) {
    this.params = params;
    this.eGui = document.createElement('div');
    
    this.eGui.setAttribute("style", "color: green");

    var o = document.createElement("button");

    o.setAttribute("style", "margin-right: 10px; margin-top: 5px; margin-left: 5px;");
    o.setAttribute("class", `btn btn-sm btn-primary bi-pencil`);
    o.setAttribute("type", "button");
    o.addEventListener("click", () => {
      params.data.editSummary(params.data.Page);
    });
    
    this.eGui.innerHTML = `<b>Page:</b> ${params.data.Page}, ${params.data.PageSummary}`;
    this.eGui.prepend(o);
  }

  getGui() {
    return this.eGui;
  }
}

// TODO: update to include orGroups
export class VisibilityCellRenderer {
  init(params) {
    this.params = params;

    var always = this.params.data.Visibility.length == 0 || this.params.data.Visibility?.some(v => v?.Comparison == Comparisons.ALWAYS);
    var never = !always && this.params.data.Visibility?.some(v => v?.Comparison == Comparisons.NEVER);
    var comparisonIcon = "";

    if (always || never) {
      comparisonIcon = always ? "bi-check-lg" : "bi-x-lg";
    }
    
    this.eGui = document.createElement('div');
    this.eGui.setAttribute('style', "margin-top: 6px; width: 100%; height: 100%");

    var o = document.createElement("button");
    o.setAttribute("class", `btn btn-sm btn-primary bi-eye`);
    o.setAttribute("type", "button");

    this.eGui.appendChild(o);

    var count = document.createElement("span");
    
    count.setAttribute("style", "font-size: small; font-weight: 200; float: right;");
    count.innerText = this.params.data.Visibility.length ? this.params.data.Visibility.length : "-";

    if (always || never) {
      var i = document.createElement("span");
      
      i.setAttribute("class", ` ${comparisonIcon}`);
      i.setAttribute("style", "font-size: large; float: right;");
      i.appendChild(count);

      this.eGui.appendChild(i);
    } else {
      this.eGui.appendChild(count);
    }
  }

  getGui() {
    return this.eGui;
  }
}

export class QuestionOptionsCellRenderer {
  init(params) {
    this.params = params;

    if (this.params.data.Type != "SINGLE"
      && this.params.data.Type != "MULTI"
      && this.params.data.Type != "MULTI_BUTTONS"
      && this.params.data.Type != "SINGLE_BUTTONS")
    {
      this.eGui = document.createElement('div');
      var na = document.createElement("span");

      na.innerText = "N/A";
      
      this.eGui.appendChild(na);
    } else {
      var icon = "bi-list-task";
      
      this.eGui = document.createElement('div');
      const button = document.createElement('div');
      button.setAttribute('class', "btn-group")
      button.setAttribute('style', "margin-top: 6px")

      var o = document.createElement("button");

      o.setAttribute("class", `btn btn-sm btn-primary ${icon}`);
      o.setAttribute("type", "button");
      var count = document.createElement("span");
      
      count.setAttribute("style", "font-size: small; font-weight: 200; margin-left: 5px;");
      count.innerText = this.params.data.Options.length;
      
      button.appendChild(o);
      this.eGui.appendChild(button);
      this.eGui.appendChild(count);
    }
  }

  getGui() {
    return this.eGui;
  }
}

export class QuestionTextCellRenderer {
  init(params) {
    this.params = params;

    this.eGui = document.createElement('div');
    var o = document.createElement("span");

    o.innerHTML = this.params.data.Text;
    
    this.eGui.appendChild(o);
  }

  getGui() {
    return this.eGui;
  }
}

export class HelpTextCellRenderer {
  init(params) {
    this.params = params;

    this.eGui = document.createElement('div');
    var o = document.createElement("span");

    o.innerHTML = this.params.data.HelpText ?? "";
    
    this.eGui.appendChild(o);
  }

  getGui() {
    return this.eGui;
  }
}

// TODO: update to include orGroups
export class RequiredCellRenderer {
  init(params) {
    this.params = params;

    var always = this.params.data.Required?.some(v => v?.Comparison == Comparisons.ALWAYS);
    var never = !always && this.params.data.Required?.some(v => v?.Comparison == Comparisons.NEVER);
    var comparisonIcon = "";

    if (always || never) {
      comparisonIcon = always ? "bi-check-lg" : "bi-x-lg";
    }
    
    this.eGui = document.createElement('div');
    this.eGui.setAttribute('style', "margin-top: 6px; width: 100%; height: 100%");

    var o = document.createElement("button");
    o.setAttribute("class", `btn btn-sm btn-primary bi-lock`);
    o.setAttribute("type", "button");

    this.eGui.appendChild(o);

    var count = document.createElement("span");
      
    count.setAttribute("style", "font-size: small; font-weight: 200; float: right;");
    count.innerText = this.params.data.Required.length ? this.params.data.Required.length : "-";

    if (always || never) {
      var i = document.createElement("span");
      
      i.setAttribute("class", ` ${comparisonIcon}`);
      i.setAttribute("style", "font-size: large; float: right;");
      i.appendChild(count);

      this.eGui.appendChild(i);
    } else {
      this.eGui.appendChild(count);
    }
  }

  getGui() {
    return this.eGui;
  }
}

export class ValidationCellRenderer {
  init(params) {
    this.params = params;
    
    this.eGui = document.createElement('div');
    const button = document.createElement('div');

    button.setAttribute('class', "btn-group")
    button.setAttribute('style', "margin-top: 6px")

    var icon = "bi-shield-fill-check";

    var o = document.createElement("button");

    o.setAttribute("class", `btn btn-sm btn-primary ${icon}`);
    o.setAttribute("type", "button");

    var count = document.createElement("span");
    
    count.setAttribute("style", "font-size: small; font-weight: 200; margin-left: 5px;");
    count.innerText = this.params.data.Validations.length ? this.params.data.Validations.length : "-";

    button.appendChild(o)
    this.eGui.appendChild(button);
    this.eGui.appendChild(count);
  }

  getGui() {
    return this.eGui;
  }
}

export class InterpretationsCellRenderer {
  init(params) {
    this.params = params;

    const icon = "bi-journal-check";
    
    this.eGui = document.createElement('div');
    const button = document.createElement('div');

    button.setAttribute('class', "btn-group");
    button.setAttribute('style', "margin-top: 6px");

    var o = document.createElement("button");

    o.setAttribute("class", `btn btn-sm btn-primary ${icon}`);
    o.setAttribute("type", "button");

    var count = document.createElement("span");
    
    count.setAttribute("style", "font-size: small; font-weight: 200; margin-left: 5px;");
    count.innerText = this.params.data.Interpretations.length > 0 ? this.params.data.Interpretations.length : "-";

    button.appendChild(o)
    this.eGui.appendChild(button);
    this.eGui.appendChild(count);
  }

  getGui() {
    return this.eGui;
  }
}

export class AllRulesCellRenderer {
  init(params) {
    this.params = params;
    
    this.eGui = document.createElement('div');

    this.eGui.setAttribute('class', "btn-group")
    this.eGui.setAttribute('style', "margin-top: 6px")

    var icon = "bi-eyeglasses";

    var o = document.createElement("button");

    o.setAttribute("class", `btn btn-sm btn-primary ${icon}`);
    o.setAttribute("type", "button");
    o.addEventListener("click", () => {

    })

    this.eGui.appendChild(o);
  }

  getGui() {
    return this.eGui;
  }
}
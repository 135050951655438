import sizeof from "object-sizeof";

// CLONED IN FUNCTIONS DIR.

export class Message {
  // Data fields
  MessageSid: string;
  AccountSid: string;
  From: string;
  To: string;
  Body: string;
  NumMedia: string;
  NumSegments: string;
  MediaContentType: any;
  MediaUrl: any;
  DateTime: string;
  Read: boolean = false;

  constructor(
      messageSid: string,
      accountSid: string,
      from: string,
      to: string,
      body: string,
      numMedia: string,
      numSegments: string,
      mediaContentType: any,
      mediaUrl: any,
      date?: string,
      read?: boolean
  ) {
    this.MessageSid = messageSid;
    this.AccountSid = accountSid;
    this.From = from;
    this.To = to;
    this.Body = body;
    this.NumMedia = numMedia;
    this.NumSegments = numSegments;
    this.MediaContentType = mediaContentType;
    this.MediaUrl = mediaUrl;
    this.Read = !!read;

    if (date) {
      this.DateTime = date;
    } else {
      this.DateTime = new Date().toISOString();
    }
  }
}

export function parseMessage(data: any): Message {
  return new Message(
      data.MessageSid || "",
      data.AccountSid || "",
      data.From || "",
      data.To || "",
      data.Body || "",
      data.NumMedia || 0,
      data.NumSegments || 0,
      data.MediaContentType,
      data.MediaUrl,
      data.DateTime,
      data.Read
  );
}

export function parseMessagesFromObject(data: any): Messages {
  const messages = new Messages();

  // loop messages and parse/add them to messages
  if (data.Messages) {
    for (let j = 0; j < data.Messages.length; j++) {
      const contact = parseMessage(data.Messages[j]);

      messages.add(contact);
    }
  }

  return messages;
}

export function addMessagesFromObject(messages: Messages, data: any) {
  // loop messages and parse/add them to messages
  if (data.Messages) {
    for (let j = 0; j < data.Messages.length; j++) {
      const contact = parseMessage(data.Messages[j]);

      messages.add(contact);
    }
  }
}

export class Messages {
  maxLimit = 1048576 - 1576; // 1 MB max with some wiggle room.

  constructor(public Messages?: Message[][]) { }

  add(contact: Message) {
    if (this.Messages) {
      if (this.Messages[this.Messages.length - 1] && sizeof(this.Messages[this.Messages.length - 1]) < this.maxLimit) {
        this.Messages[this.Messages.length - 1].push(contact);
      } else {
        this.Messages.push([contact]);
      }
    } else {
      this.Messages = [[contact]];
    }
    return this.Messages.length - 1;
  }

  docCount() {
    let count = 0;

    if (!this.Messages) {
      return count;
    }

    count = this.Messages.length;

    return count;
  }

  markReadForContact(phoneNumber: string) {
    if (!this.Messages) {
      return;
    }

    for (let i = 0; i < this.Messages.length; i++) {
      for (let j = 0; j < this.Messages[i].length; j++) {
        let from = this.Messages[i][j].From.replace(/\D/g,'');
        let testNumber = phoneNumber.replace(/\D/g,'');

        let matches = from.endsWith(testNumber);
        
        if (matches) {
          this.Messages[i][j].Read = true;
        }
      }
    }
  }

  markUnreadForContact(phoneNumber: string) {
    if (!this.Messages) {
      return;
    }

    for (let i = this.Messages.length - 1; i >= 0; i--) {
      for (let j = this.Messages[i].length - 1; j >= 0; j--) {
        let from = this.Messages[i][j].From.replace(/\D/g,'');
        let testNumber = phoneNumber.replace(/\D/g,'');

        let matches = from.endsWith(testNumber);
        
        if (matches) {
          this.Messages[i][j].Read = false;
          return; // bail so we only mark the most recent as unread
        }
      }
    }
  }

  getMessageListForSaving(docIndex: number) {
    if (!this.Messages || docIndex < 0 || docIndex >= this.Messages.length) {
      return null;
    }

    const isFull = sizeof(this.Messages[docIndex]) >= this.maxLimit;
    let DateTime = new Date().toISOString();

    if (this.Messages[docIndex].length > 0) {
      DateTime = this.Messages[docIndex][0].DateTime;
    }

    const result = {
      DateTime: DateTime,
      Full: isFull,
      Messages: JSON.parse(JSON.stringify(this.Messages[docIndex]))
    };

    return result;
  }
}

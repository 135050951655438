import { v4 as uuidv4 } from 'uuid';
import sizeof from 'object-sizeof';

export class Contact {
    // Data fields
    PhoneNumber: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Notes: string;
    ID: string;

    constructor(
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
        notes: string,
        id: string
    ) {
        this.PhoneNumber = phoneNumber;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.Email = email;
        this.Notes = notes;

        if (id) {
          this.ID = id;
        } else {
          this.ID = uuidv4();
        }
    }
}

export function parseContact(data: any): Contact {
  return new Contact(data.FirstName || "", data.LastName || "", data.PhoneNumber || "", data.Email || "", data.Notes || "", data.ID || "");
}

export function parseContactsFromObject(data: any): Contacts {
  var contacts = new Contacts();

  // loop contacts and parse/add them to contacts
  if (data.contacts) {
    for (let j = 0; j < data.contacts.length; j++) {
      var contact = parseContact(data.contacts[j]);

      contacts.add(contact);
    }
  }

  return contacts;
}

export function parseAllContactsFromArray(data: any[]): Contacts {
  var contacts = new Contacts();

  if (data.length) {
    for (let i = 0; i < data.length; i++) {
      contacts.add(parseContact(data[i]));
    }
  }

  return contacts;
}

export function getContactByEmail(contacts: Contact[], email: string) {
  var index = getIndexByEmail(contacts, email);

  if (index == -1 || index >= contacts.length)
    return undefined;
  else
    return contacts[index];
}

export function getContactByPhone(contacts: Contact[], phoneNumber: string) {
  var index = getIndexByPhone(contacts, phoneNumber);

  if (index == -1 || index >= contacts.length)
    return undefined;
  else
    return contacts[index];
}

export function getContactByName(contacts: Contact[], firstName: string, lastName: string) {
  var index = getIndexByName(contacts, firstName, lastName);

  if (index == -1 || index >= contacts.length)
    return undefined;
  else
    return contacts[index];
}

export function getIndexByEmail(contacts: Contact[], email: string) {
  if (!contacts || !contacts.length) {
    return -1;
  }

  var contactIndex = contacts.findIndex(o => o.Email.toLowerCase() == email.toLowerCase());

  return contactIndex;
}

export function getIndexByPhone(contacts: Contact[], phoneNumber: string) {
  if (!contacts || !contacts.length) {
    return -1;
  }

  var contactIndex = contacts.findIndex(o => o.PhoneNumber.replace(/\D/g,'').endsWith(phoneNumber.replace(/\D/g,'')) || phoneNumber.replace(/\D/g,'').endsWith(o.PhoneNumber.replace(/\D/g,'')));

  return contactIndex;
}

export function getIndexByName(contacts: Contact[], firstName: string, lastName: string) {
  if (!contacts || !contacts.length) {
    return -1;
  }

  var contactIndex = contacts.findIndex(o => o.FirstName.toLowerCase() == firstName.toLowerCase() && o.LastName.toLowerCase() == lastName.toLowerCase());

  return contactIndex;
}

export class Contacts implements Iterable<Contact> {
  maxLimit = 1048576 - 576; // 1 MB max with some wiggle room.

  constructor(public Contacts?: Contact[][]) { }

  add(contact: Contact) {
    if (this.Contacts) {
        if (this.Contacts[this.Contacts.length - 1] && sizeof(this.Contacts[this.Contacts.length - 1]) < this.maxLimit) {
            this.Contacts[this.Contacts.length - 1].push(contact);
        } else {
            this.Contacts.push([contact]);
        }
    } else {
        this.Contacts = [[contact]];
    }
    return this.Contacts.length - 1;
  }
  
  remove(index: number) {
    let rowPointer = 0;

    if (this.Contacts) {
      while (index >= this.Contacts[rowPointer].length && index > 0)
        index -= this.Contacts[rowPointer++].length;
      
      if (index < 0) {
        console.error('Index should not be less than 0');
      } else {
        this.Contacts[rowPointer].splice(index, 1);
      }
    }
  }

  update(index: number, contact: Contact) {
    let rowPointer = 0;

    if (!this.Contacts) {
        return null;
    }

    while (index >= this.Contacts[rowPointer].length && index > 0)
        index -= this.Contacts[rowPointer++].length;
    
    if (index < 0) {
        console.error('Index should not be less than 0');
        return null;
    }
    
    this.Contacts[rowPointer][index] = contact;
  }

  at (index: number): Contact | null {
    let rowPointer = 0;
    if (!this.Contacts) {
        return null;
    }
    while (index >= this.Contacts[rowPointer].length && index > 0)
        index -= this.Contacts[rowPointer++].length;
    if (index < 0) {
        console.error('Index should not be less than 0');
        return null;
    }
    return this.Contacts[rowPointer][index];
  }

  docCount() {
    let count = 0;

    if (!this.Contacts)
      return count;

    count = this.Contacts.length;

    return count;
  }

  contactCount() {
    let count = 0;

    if (!this.Contacts)
      return count;

    for (var i = 0; i < this.Contacts.length; i++)
      count += this.Contacts[i].length;

    return count;
  }

  getContactListForSaving(docIndex: number) {
    if (!this.Contacts || docIndex < 0 || docIndex >= this.Contacts.length) {
      return null;
    }
  
    var result = {
      contacts: JSON.parse(JSON.stringify(this.Contacts[docIndex]))
    };
  
    return result;
  }

  [Symbol.iterator](): Iterator<Contact, any, undefined> {
    let rowPointer = 0;
    let colPointer = 0;
    let contacts = this.Contacts;

    return {
      next(): IteratorResult<Contact, any> {
        if (contacts && rowPointer < contacts.length && colPointer < contacts[rowPointer].length) {
            return { done: false, value: contacts[rowPointer][colPointer++] };
        } else if (contacts && rowPointer < contacts.length) {
            rowPointer++;
            colPointer = 0;
            return this.next();
        } else {
            return { done: true, value: null };
        }
      }
    };
  }
}

import React from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCompany, useUser } from '../../Store/storeFunctions';
import { BeatLoader } from 'react-spinners';
import AcknowledgeLicenseAgreement from './AcknowledgeLicenseAgreement';

const functions = getFunctions();

const getAccountSession = httpsCallable(functions, "getAccountSession");
const initiateSubscriptionSession = httpsCallable(functions, "initiateSubscriptionSession");
const getAccountSubscription = httpsCallable(functions, "getAccountSubscription");

const products = [
  {
    type: "BASIC",
    price: 600,
    pricePerUnit: 6,
    threshold: 100,
    frequency: 'yr',
    title: "Basic",
    description: "For 10-208 estimated submissions. $6 per submission.",
    addOns: [
      { name: "Messaging", type: "MESSAGING", price: 475 }
    ]
  },
  {
    type: "SMALL_BUSINESS",
    price: 1250,
    pricePerUnit: 5,
    threshold: 250,
    frequency: 'yr',
    title: "Small Business",
    description: "For 209-400 estimated submissions. $5 per submission.",
    addOns: [
      { name: "Messaging", type: "MESSAGING", price: 550 }
    ],
    default: true
  },
  {
    type: "PRO",
    price: 2000,
    pricePerUnit: 4,
    threshold: 500,
    frequency: 'yr',
    title: "Professional",
    description: "For 400-750 estimated submissions. $4 per submission.",
    addOns: [
      { name: "Messaging", type: "MESSAGING", price: 675 }
    ]
  },
  {
    type: "ENTERPRISE",
    price: 3000,
    pricePerUnit: 3,
    threshold: 1000,
    frequency: 'yr',
    title: "Enterprise",
    description: "For more than 750 estimated submissions. $3 per submission.",
    addOns: [
      { name: "Messaging", type: "MESSAGING", price: 1000 }
    ],
    featured: true
  }
];

function AccountSettings(props) {
  const [loading, setLoading] = React.useState();
  const [screen, setScreen] = React.useState("General");
  const [subscription, setSubscription] = React.useState();
  const [messagingSubscription, setMessagingSubscription] = React.useState();

  const [includeMessaging, setIncludeMessaging] = React.useState(undefined);

  const [showAcknowledgmentModal, setShowAcknowledgmentModal] = React.useState(false);
  const [pendingSubscription, setPendingSubscription] = React.useState(null);

  const company = useCompany();
  const user = useUser();

  React.useEffect(() => {
    if (company.companyData.stripeSubscription) {
      setLoading(true);
      getAccountSubscription({
        subscription: company.companyData.stripeSubscription
      }).then((sub) => {
        setSubscription(sub.data);
      })
      .then(async () => {
        if (company.companyData.messagingSubscription) {
          var sub = await getAccountSubscription({
            subscription: company.companyData.messagingSubscription
          });

          setMessagingSubscription(sub.data);
        }
      })
      .then(() => {
        setLoading(false);
      }).catch(err => {
        console.error(err);
        setLoading(false);
      });
    }
  }, [company.companyData.stripeSubscription]);

  async function manageSubscription() {
    setLoading(true);
    var url = await getAccountSession({ company: company.companyData?.code });
    setLoading(false);
    window.open(url.data, "_blank");
  }

  async function subscribe(product, yearly, messagingOnly) {
    let includeRegular = !messagingOnly;
    var session = await initiateSubscriptionSession({
      company: company.companyData?.code,
      email: user.user.email,
      product: product,
      includeMessaging: includeMessaging || messagingOnly,
      includeRegular: includeRegular,
      frequency: yearly ? "yearly" : "monthly"
    });

    return session?.data?.stripeSession;
  }

  function addAddOn(type) {
    switch (type) {
      case "MESSAGING": setIncludeMessaging(!includeMessaging); break;
      default: break;
    }
  }

  function getAddOn(type) {
    switch (type) {
      case "MESSAGING": return includeMessaging;
      default: return false;
    }
  }

  function getTotal(subTier, isYearly) {
    let tier = products.find(p => p.type == subTier);
    let priceRecurring = tier.price;
    let priceToday = 500; // Implementation

    if (!isYearly) {
      priceRecurring = priceRecurring / 10;
    }

    // Add all add ons based on yearly or not if added.
    for (let i = 0; i < tier.addOns.length; i++) {
      let used = getAddOn(tier.addOns[i].type);

      if (used) {
        if (isYearly) {
          priceRecurring += tier.addOns[i].price;
        } else {
          priceRecurring += tier.addOns[i].price / 10;
        }
      }
    }

    // add recurring to today
    priceToday += priceRecurring;

    return <div>
      <div style={{ ...styles.productPrice, display: 'inline'}}>{priceToday.toLocaleString("en-US", { style: 'currency', currency: 'USD' })}<div style={styles.productFrequencyCurrent}> today</div></div><br />
      <div style={{ ...styles.productPrice, display: 'inline', fontSize: 24 }}>{priceRecurring.toLocaleString("en-US", { style: 'currency', currency: 'USD' })}<div style={styles.productFrequencyCurrent}>/{isYearly ? "year" : "month"}</div></div>
    </div>
  }

  function initiateSubscription(subTier, isYearly, messagingOnly) {
    // Store the subscription details and show modal
    setPendingSubscription({
      subTier,
      isYearly,
      messagingOnly
    });
    setShowAcknowledgmentModal(true);
  }

  function handleAcknowledgmentAccepted() {
    setShowAcknowledgmentModal(false);
    
    // Now proceed with the subscription
    if (pendingSubscription) {
      setLoading(true);
      subscribe(
        pendingSubscription.subTier, 
        pendingSubscription.isYearly, 
        pendingSubscription.messagingOnly
      ).then(session => {
        if (session?.url) {
          window.open(session.url, "_blank");
        }
        setLoading(false);
      });
      setPendingSubscription(null);
    }
  }

  function renderProducts() {
    const productPills = products.map((p, i) => {
      return <button className={`nav-link btn ${p.default ? "active" : ""}`}
        id={`v-pills-${p.type}-tab`}
        key={p.type}
        data-bs-toggle="pill"
        data-bs-target={`#v-pills-${p.type}`}
        type="button"
        role="tab"
        aria-controls={`v-pills-${p.type}`}
        aria-selected={p.default == true}
        style={{ textAlign: 'left', width: 200, borderWidth: 1, borderStyle: 'solid', borderColor: 'var(--blue)' }}
      >
        {p.title}
      </button>
    });

    const contentPages = products.map((p, i) => {
      let yearlyAddOns = p.addOns.map((ao, aoi) => {
        return <div key={`YearlyAddOn${i}`}>
          <div style={styles.productPrice}>+{ao.price.toLocaleString("en-US", { style: 'currency', currency: 'USD' })}</div>
        </div>
      });
      
      let monthlyAddOns = p.addOns.map((ao, aoi) => {
        return <div key={`MonthlyAddOn${i}`}>
          <div style={styles.productPrice}>+{(ao.price / 10).toLocaleString("en-US", { style: 'currency', currency: 'USD' })}</div>
        </div>
      });

      let addOnTypes = p.addOns.map((ao, aoi) => {
        return <div className="form-check form-switch">
          <input className="form-check-input" type="checkbox" id={`include${ao.type}`} checked={getAddOn(ao.type)} onChange={(e) => addAddOn(ao.type)} />
          <label className="form-check-label" htmlFor={`include${ao.type}`}>{ao.name}</label>
        </div>
      });

      return <div className={`tab-pane fade ${p.default ? "show active" : ""}`} id={`v-pills-${p.type}`} key={`${p.type}_${i}`} role='tabpanel' aria-labelledby={`v-pills-${p.type}-tab`}>
        <div style={{ display: 'flex', flexDirection: "row" }}>
          <div className='center' style={{ display: 'flex', flex: 1, borderRightStyle: 'solid', borderRightWidth: 1, flexDirection: 'column' }}>
            <div className='mb-3'>Options</div>

            <div style={styles.productAddOn}>Tax Checklist</div>
            <div style={styles.productAddOn}>Implementation <br />(One time fee)</div>
            <div style={styles.productAddOn}>{addOnTypes}</div>
            <div style={{ ...styles.productAddOn, height: 150 }}>Total</div>
            
            <div style={{ margin: 'auto', minWidth: 100, marginTop: 24 }}></div>
          </div>

          <div className='center' style={{ display: 'flex', flex: 1, borderRightStyle: 'solid', borderRightWidth: 1, flexDirection: 'column' }}>
            <div className='mb-3'>Yearly</div>
            
            <div style={styles.productAddOn}><div style={styles.productPrice}>{p.price.toLocaleString("en-US", { style: 'currency', currency: 'USD' })}</div></div>
            <div style={styles.productAddOn}><div style={styles.productPrice}>+{(500).toLocaleString("en-US", { style: 'currency', currency: 'USD' })}</div></div>
            <div style={styles.productAddOn}>{yearlyAddOns}</div>
            <div style={{ ...styles.productAddOn, height: 150 }}>{getTotal(p.type, true)}</div>
            
            <div style={{ margin: 'auto', minWidth: 100, marginTop: 24 }}>
              <button className="btn btn-sm btn-primary"
                style={{ ...styles.availableProduct }}
                onClick={() => {
                  initiateSubscription(p.type, true, false);
                }}
              >
                Subscribe
              </button>
            </div>
          </div>

          <div className='center' style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div className='mb-3'>Monthly</div>

            <div style={styles.productAddOn}><div style={styles.productPrice}>{(p.price / 10).toLocaleString("en-US", { style: 'currency', currency: 'USD' })}</div></div>
            <div style={styles.productAddOn}><div style={styles.productPrice}>+{(500).toLocaleString("en-US", { style: 'currency', currency: 'USD' })}</div></div>
            <div style={styles.productAddOn}>{monthlyAddOns}</div>
            <div style={{ ...styles.productAddOn, height: 150 }}>{getTotal(p.type, false)}</div>

            <div style={{ margin: 'auto', minWidth: 100, marginTop: 24 }}>
              <button className="btn btn-sm btn-primary"
                style={styles.availableProduct}
                onClick={() => {
                  initiateSubscription(p.type, false, false);
                }}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    });

    return <div style={{ ...styles.product }}>
      <div className="nav nav-pills btn-group" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        {productPills}
      </div>
      <div className="tab-content m-5" id="v-pills-tabContent">
        {contentPages}
      </div>
    </div>
  }

  function renderChosenSubscription() {
    if (!subscription) {
      return <><button className="btn btn-sm btn-primary" style={{width: '100%' }} onClick={manageSubscription}>Manage Subscription</button></>;
    }

    let product = products.find(p => p.type == subscription.type);
    
    if (!product) {
      return <><button className="btn btn-sm btn-primary" style={{width: '100%' }} onClick={manageSubscription}>Manage Subscription</button></>;
    }
    
    let messagingProduct = product.addOns.find(ao => ao.type == "MESSAGING");
    
    var end_date = new Date();
    end_date.setTime(subscription.current_period_end * 1000);
    
    return <div style={{ ...styles.productCurrent, textAlign: 'center' }}>
      <div style={{ ...styles.productDescriptionCurrent, margin: 0 }}>Current Plan</div>
      <div style={{ ...styles.productTitleCurrent }}>
        {product.title}
        <div style={styles.productDescriptionCurrent}>
          { company.companyData.extraFeatures.messaging || messagingSubscription ? "Messaging included" : "Messaging not included" }
        </div>
      </div>


      {
        company.companyData.extraFeatures.messaging || messagingSubscription ? null :
        <div>
          <hr />
          <button className="btn btn-sm btn-primary" style={{width: '100%', height: '100%'}} onClick={() => initiateSubscription(product.type, true, true)}>
            Add Messaging +{messagingProduct.price.toLocaleString("en-US", { style: 'currency', currency: 'USD' })}/yr
          </button>

          <div style={{ textAlign: 'center', ...styles.productDescriptionCurrent, margin: 0 }}>- or -</div>

          <button className="btn btn-sm btn-primary" style={{width: '100%', height: '100%'}} onClick={() => initiateSubscription(product.type, false, true)}>
            Add Messaging +{(messagingProduct.price/10).toLocaleString("en-US", { style: 'currency', currency: 'USD' })}/mo
          </button>
        </div>
      }

      <hr />

      <div style={{ ...styles.productPriceCurrent, textAlign: 'center' }}>
        ${product.price}/
        <div style={styles.productFrequencyCurrent}>
          {product.frequency}
        </div>
      </div>

      <hr />

      <div style={{ ...styles.productDescriptionCurrent, textAlign: 'center', opacity: 1 }}>{ subscription.status == 'active' ? "Renews" : "Ends" } {end_date.toLocaleDateString()}</div>

      <hr />
      
      <button className="btn btn-sm btn-primary" style={{width: '100%', height: '100%'}} onClick={manageSubscription}>Manage Subscription</button>
    </div>
  }

  function renderGeneralButton() {
    if (screen == "General") {
      return <div style={styles.menu}><b>General</b></div>;
    }

    return <div>
      <div style={styles.menuButton} onClick={() => setScreen("General")}>General</div>
    </div>
  }

  function renderGeneralBody() {
    if (screen !== "General")
      return null;

    if (!company.companyData.stripeCustomerId || !company.companyData.isSubscribed)
      return renderProducts();
    else {
      return <div>
        { renderChosenSubscription() }
      </div>;
    }
  }

  function renderGetStartedButton() {
    if (screen == "Start") {
      return <div style={styles.menu}><b>Get Started</b></div>;
    }

    return <div>
      <div style={styles.menuButton} onClick={() => setScreen("Start")}>Get Started</div>
    </div>
  }

  function renderGetStartedBody() {
    if (screen !== "Start") {
      return null;
    }

    return <div className="accordion" id="accordionSetupSteps" style={{ width: '90%' }}>
      <div className="accordion-item">
        <h1 className="accordion-header" id={`heading1`}>
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse1`} aria-expanded="true" aria-controls={`collapse1`}>
            <h3>#1 Subscribe to one of our plans</h3>
          </button>
        </h1>
        <div id={`collapse1`} className="accordion-collapse collapse show" aria-labelledby={`heading1`} data-bs-parent="#accordionSetupSteps">
          <div className="accordion-body" style={{ fontSize: "1.25rem" }}>
            Our service is a paid service and requires an active subscription. There are 3 plans to choose from. Go to General settings to choose a plan or manage your current subscription.
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h1 className="accordion-header" id={`heading4`}>
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse4`} aria-expanded="false" aria-controls={`collapse4`}>
            <h3>#2 Configure Email Templates</h3>
          </button>
        </h1>
        <div id={`collapse4`} className="accordion-collapse collapse" aria-labelledby={`heading4`} data-bs-parent="#accordionSetupSteps">
          <div className="accordion-body" style={{ fontSize: "1.25rem" }}>
            Navigate to the Form Selector page and verify that any form that you intend to use says YES in the Email Template column. If it says NO, check the box on that row and click 'Edit Form Properties'. Alter the template to your desires. There are helpful tips on that page to assist you in adding your logo and referencing questions from your forms. When you are done click Save at the bottom right of the page.
            <div style={{ borderRadius: 10, borderWidth: 1, borderColor: '#045AA1', borderStyle: 'solid', width: '80%', height: 800, padding: 20 }}>
              <iframe src="https://scribehow.com/embed/Managing_Form_Properties_in_TaxChecklist__ZNtK23Z6ROCt6r_OaFcmtQ?skipIntro=true" width="100%" height="100%" allowfullscreen frameBorder="0"></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h1 className="accordion-header" id={`heading2`}>
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse2`} aria-expanded="false" aria-controls={`collapse2`}>
            <h3>Optional: Configure Outgoing Email</h3>
          </button>
        </h1>
        <div id={`collapse2`} className="accordion-collapse collapse" aria-labelledby={`heading2`} data-bs-parent="#accordionSetupSteps">
          <div className="accordion-body" style={{ fontSize: "1.25rem" }}>
            A large benefit to using our software is that you are in control of your outgoing email. If you would like the emails to be sent from your own accout, then you need to give us permissions to send the checklists on your behalf. <br /><br />

            <b>How does it work?</b> - Our service has integrated with Gmail to give you the security and reliability everyone is familiar with. When your client submits one of your forms, our application will tell your Gmail account to send an email to your client. This email will follow your configured email template and will include their required documents based on their form answers.<br /><br />

            <b>What do you need to do?</b> - We need two pieces of information in order to provide this service. A Gmail email address, and a Gmail <i>App Password</i>. Enter these things into the Company Options page. <br /> <br />
            
            <ol>
              <li><b>Gmail email address:</b> This will be an account that you control and plan to use for client interaction. (i.e. checklist@yourfirm.com)</li>
              <li>
                <b>App Password:</b> An <i>App Password</i> is a Gmail generated password that allows applications to send emails on your behalf. There are a few steps to do this. The steps here will help get you there and set up the proper security for your Gmail account.
                <div style={{ borderRadius: 10, borderWidth: 1, borderColor: '#045AA1', borderStyle: 'solid', width: '80%', height: 800, padding: 20 }}>
                  <iframe src="https://scribehow.com/embed/Google_Workflow__uluCj7NCRRO2RrKtbBaHog?skipIntro=true" width="100%" height="100%" allowfullscreen frameBorder="0"></iframe>
                </div>
              </li>
            </ol>

            That's it! Your outgoing email is now set up an ready.
          </div>
        </div>
      </div>
    </div>;
  }

  function render() {
    if (loading) {
      return (<div className="loadingSpinner"><BeatLoader color={"#045AA1"} /></div>);
    }

    return (<>
        <div style={styles.settingsContainer}>
            <div style={{width: '10%'}}>
                {renderGeneralButton()}
                {renderGetStartedButton()}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {renderGeneralBody()}
              {renderGetStartedBody()}
            </div>
        </div>
        
        {showAcknowledgmentModal && (
        <AcknowledgeLicenseAgreement
          close={() => {
            setShowAcknowledgmentModal(false);
            setPendingSubscription(null);
          }}
          accept={handleAcknowledgmentAccepted}
        />
      )}
    </>
    );
  }

  return render();
}

const styles = {
    settingsContainer: {
      fontFamily: 'Open Sans',
      fontSize: 26,
      fontWeight: 'Regular',
      color: '#1F1F1F',
      width: '100%',
      height: '100%',
      paddingLeft: '10%',
      marginTop: '12px',
      marginBottom: '12px',
      display: 'flex',
      paddingTop: '2vh'
    },
    product: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      position: 'relative',
      minHeight: 0,
      overflowY: 'auto',
      margin: 20,
      padding: 20,
      borderRadius: 10,
      backgroundColor: 'hsla(0,0%,10%,.05)',
      maxHeight: 700
    },
    productAddOn: {
      fontWeight: 200,
      fontSize: 20,
      padding: 12,
      height: 75,
      borderBottomWidth: 1,
      borderBottomStyle: 'dotted',
      borderBottomColor: 'black',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center'
    },
    productDescription: {
      fontWeight: 200,
      opacity: .6,
      fontSize: 16,
      marginBottom: 25
    },
    productTitle: {
      fontWeight: 600
    },
    subscribeButton: {
      height: 40,
      position: 'absolute',
      bottom: 30,
      width: '60%',
      left: '20%',
      borderRadius: 10
    },
    productPrice: {
      fontSize: 30,
      fontWeight: 700
    },
    productFrequency: {
      display: 'inline',
      fontSize: 16,
      fontWeight: 300,
      opacity: .6
    },
    productPricePerUnit: {
      fontSize: 20,
      fontWeight: 500
    },
    productFeatured: {
      fontSize: 15,
      backgroundColor: '#FFF',
      float: 'right',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 5,
      color: '#045aa1',
      borderWidth: 1,
      borderColor: '#045aa1',
      borderStyle: 'solid'
    },
    availableProduct: {
      fontSize: 15,
      backgroundColor: '#FFF',
      color: '#045aa1',
      borderWidth: 1,
      borderColor: '#045aa1',
      borderStyle: 'solid',
      width: '100%',
      height: '100%'
    },
    menu: {
      color: '#045AA1',
      marginBottom: 20
    },
    menuButton: {
      color: '#1F1F1F',
      marginBottom: 20,
      cursor: 'pointer'
    },
    productCurrent: {
      display: 'inline-block',
      position: 'relative',
      width: 450,
      borderRadius: 10,
      backgroundColor: 'hsla(0,0%,10%,.05)',
      margin: 20,
      padding: 50
    },
    productDescriptionCurrent: {
      fontWeight: 200,
      opacity: .6,
      fontSize: 16,
      marginBottom: 25
    },
    productTitleCurrent: {
      fontWeight: 600,
      fontSize: 36
    },
    productPriceCurrent: {
      fontSize: 30,
      fontWeight: 700
    },
    productFrequencyCurrent: {
      display: 'inline',
      fontSize: 16,
      fontWeight: 300,
      opacity: .6
    },
    productPricePerUnitCurrent: {
      fontSize: 20,
      fontWeight: 500
    }
};

export default AccountSettings;